import { useState, MouseEvent } from "react";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CardContent from "@mui/material/CardContent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Stack from "@mui/material/Stack";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import HelpOutline from "@mui/icons-material/HelpOutline";
import MenuItem from "@mui/material/MenuItem";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import { useDmsDocumentHref, useIsEditMode } from "features/DMS/hooks";
import { useDmsModalContext } from "features/DMS/hooks/useDmsModal";
import { NotificationDot } from "features/Notifications/NotificationDot";
import { useFormatDate } from "core/hooks/useFormatDate";
import { useTranslation } from "react-i18next";
import Image from "next/legacy/image";
import { SvgFileIcon } from "core/components/SvgFileIcon";
import { DocDocumentDto } from "features/DMS/entities";
import { DmsPermissionsDisplay } from "../DmsPermissionsDisplay";

export interface DmsDocumentTileProps {
  id: string;
  name: string;
  parentId: string;
  publishTime?: string | Date | null;
  fileName?: string | null | undefined;
  documentLink?: string | null | undefined;
  fileId?: string | null | undefined;
  thumbnailId?: string | null | undefined;
  thumbnailName?: string | null | undefined;
  description?: string | null | undefined;
  availableTo?: string | Date | null;
  permissions: DocDocumentDto["permissions"];
  showPermissions: boolean;
}

const IMAGE_HEIGHT = 150;

function TileImage({
  fileName,
  thumbnailId,
  thumbnailName,
  documentLink,
}: {
  fileName?: string | null | undefined;
  thumbnailId?: string | null | undefined;
  thumbnailName?: string | null | undefined;
  documentLink?: string | null | undefined;
}) {
  const imageHref = useDmsDocumentHref(thumbnailId, thumbnailName);
  if (thumbnailId && thumbnailName) {
    return (
      <Box
        sx={{
          position: "relative",
          height: IMAGE_HEIGHT,
          backgroundColor: "background.default",
        }}
      >
        <Image
          src={imageHref}
          sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              25vw"
          alt={fileName ?? undefined}
          layout="fill"
          objectFit="contain"
        />
      </Box>
    );
  }
  if (documentLink) {
    return (
      <Stack
        bgcolor="background.default"
        height={IMAGE_HEIGHT}
        direction="row"
        justifyContent="center"
        alignItems="center"
        color="text.secondary"
      >
        <OpenInNewIcon color="inherit" sx={{ fontSize: "6rem" }} />
      </Stack>
    );
  }
  if (fileName) {
    return (
      <Stack
        bgcolor="background.default"
        height={IMAGE_HEIGHT}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <SvgFileIcon sx={{ fontSize: "6rem" }} fileName={fileName} />
      </Stack>
    );
  }
  return null;
}

export function DmsDocumentTile({
  id,
  name,
  description,
  fileId,
  fileName,
  documentLink,
  publishTime,
  availableTo,
  thumbnailId,
  thumbnailName,
  parentId,
  permissions,
  showPermissions,
}: DmsDocumentTileProps) {
  const { libraryId } = useDmsModalContext();
  const isEditMode = useIsEditMode(libraryId);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const { openEditDocumentModal, openDeleteDocumentModal } =
    useDmsModalContext();

  function onEditClick(e: MouseEvent) {
    openEditDocumentModal(id, parentId);
    setMenuAnchor(null);
    e.stopPropagation();
  }

  function onDeleteClick(e: MouseEvent) {
    openDeleteDocumentModal(id, parentId);
    setMenuAnchor(null);
    e.stopPropagation();
  }
  const href = useDmsDocumentHref(fileId, fileName, documentLink);
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:DMSDocumentListItem",
  });
  const formatDate = useFormatDate({ dateStyle: "short" });

  const addedDate = t("addedLabel", {
    publishTime: (publishTime && formatDate(publishTime)) || "",
  });
  const expiryDate =
    availableTo &&
    t("expiresLabel", {
      availableTo: formatDate(availableTo),
    });
  return (
    <>
      <Card key={id} sx={{ position: "relative" }}>
        {isEditMode && (
          <IconButton
            size="small"
            onClick={(e) => {
              setMenuAnchor(e.currentTarget);
            }}
            sx={{
              position: "absolute",
              right: 6,
              top: IMAGE_HEIGHT + 10,
              zIndex: 100,
            }}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <CardActionArea sx={{ height: "100%" }} href={href} target="_blank">
          <TileImage
            documentLink={documentLink}
            fileName={fileName}
            thumbnailId={thumbnailId}
            thumbnailName={thumbnailName}
          />
          <CardContent>
            <Typography variant="h5" gutterBottom mr={isEditMode ? 3 : 0}>
              {name} <NotificationDot id={id} mode="self" />
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {addedDate ?? " "}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {expiryDate ?? " "}
            </Typography>
            <Tooltip
              PopperProps={{ sx: { whiteSpace: "pre-wrap" } }}
              title={description ?? null}
            >
              <Typography
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  whiteSpace: "pre-wrap",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 5,
                }}
                pt={1}
                variant="body2"
              >
                {description}
              </Typography>
            </Tooltip>

            <Collapse in={showPermissions}>
              <Box>
                <Divider sx={{ width: "100%", mb: 2 }}>
                  <Tooltip title={t("permissionsTooltip")}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      color="text.secondary"
                    >
                      <Typography variant="overline">
                        {t("permissionsDividerText")}
                      </Typography>
                      <HelpOutline
                        sx={{ mb: 0.25 }}
                        color="inherit"
                        fontSize="small"
                      />
                    </Stack>
                  </Tooltip>
                </Divider>
                <DmsPermissionsDisplay
                  sx={{
                    width: "100%",
                    flex: 1,
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                    gap: 1,
                  }}
                  permissions={permissions}
                  docLibraryId={libraryId}
                />
              </Box>
            </Collapse>
          </CardContent>
        </CardActionArea>
      </Card>
      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem onClick={onEditClick}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem onClick={onDeleteClick}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
    </>
  );
}
