import {
  AttachmentsObject,
  DocDocumentAttachmentCreateUpdateDto,
} from "../../../../entities";

export function dmsDocumentFormAttachmentsToCreateUpdateDto(
  attachments: AttachmentsObject | undefined,
  isDocumentUpload: boolean
): DocDocumentAttachmentCreateUpdateDto[] {
  if (!attachments) {
    return [];
  }
  const values = Object.values(attachments);
  if (!isDocumentUpload) {
    const attachmentLinks = values.filter((entry) => entry.documentLink);
    const createUpdateDtos: DocDocumentAttachmentCreateUpdateDto[] =
      attachmentLinks.map((attachment) => ({
        transKey: attachment.transKey ?? "en",
        documentLink: attachment.documentLink,
        thumbnailId: attachment.thumbnailId || undefined,
        thumbnailName: attachment.thumbnailName || undefined,
      }));
    return createUpdateDtos;
  }
  const attachmentFiles = values.filter(
    (entry) => entry.fileId && entry.fileName
  );
  const createUpdateDtos: DocDocumentAttachmentCreateUpdateDto[] =
    attachmentFiles.map((attachment) => ({
      transKey: attachment.transKey ?? "en",
      fileId: attachment.fileId,
      fileName: attachment.fileName,
      thumbnailId: attachment.thumbnailId || undefined,
      thumbnailName: attachment.thumbnailName || undefined,
    }));
  return createUpdateDtos;
}
