import { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import { StyledTabs } from "core/components/StyledTabs";
import { GridAreaContainer } from "core/components/GridAreaContainer";
import { useTranslation } from "react-i18next";
import { WidgetTabValues } from "features/DMS/entities";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import { DMSWidgetList } from "./DMSWidgetList";
import { useDMSWidgetData } from "./useDMSWidgetData";
import { sortingTabMap } from "./DMSWidget";

export function DMSWidgetSinglePage() {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:DMSWidget",
  });
  const [selectedTab, setSelectedTab] =
    useState<WidgetTabValues>("publishTime");
  // const [tabIndex, setTabIndex] = useState(0);
  const sorting = sortingTabMap[selectedTab];
  const isDocumentModified =
    selectedTab === "lastModificationTime" ? true : undefined;
  const { isLoading, items } = useDMSWidgetData({
    sorting,
    maxResultCount: 5,
    isDocumentModified,
  });
  function changeTab(newTab: WidgetTabValues) {
    setSelectedTab((tab) => {
      if (tab === newTab) {
        return tab;
      }
      return newTab;
    });
  }
  const { isMobile } = useBreakpoints();
  return (
    <GridAreaContainer gridArea="dms" title={t("gridAreaContainerTitle")}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minHeight: isMobile ? "700px" : "570px",
          py: 4,
          px: isMobile ? 1 : 4,
        }}
      >
        <StyledTabs
          value={selectedTab}
          variant={isMobile ? "fullWidth" : "standard"}
        >
          <Tab
            value="publishTime"
            label={t("tabLastPublishTime")}
            onClick={() => changeTab("publishTime")}
          />
          <Tab
            value="lastModificationTime"
            label={t("tabLastModificationTime")}
            onClick={() => changeTab("lastModificationTime")}
          />
        </StyledTabs>
        <Divider />
        <Box minHeight="375px" overflow="auto">
          <DMSWidgetList
            sx={{
              mb: 4,
            }}
            isLoading={isLoading}
            documents={items}
            selectedTab={selectedTab}
          />
        </Box>
      </Paper>
    </GridAreaContainer>
  );
}
