import {
  CentrixPathsByMethod,
  ExtractSuccessType,
  ParametersProperty,
} from "core/entities/APITypes";
import useSWR from "swr";
import { APIError } from "core/entities";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { buildCentrixUrl } from "../utils/buildCentrixUrl";
import { useJsonFetcherWithHeaders } from "./useJsonFetcherWithHeaders";
import { useFetchAllPaginatedData } from "./useFetchAllPaginatedData";

export type UseCentrixApiArg<P extends CentrixPathsByMethod<"get">> = {
  path: P;
  swrOptions?: Parameters<
    typeof useSWR<ExtractSuccessType<P, "get">, APIError>
  >[2];
  shouldFetch?: boolean;
  fetchAll?: boolean;
} & ParametersProperty<P, "get">;

/**
 * Custom hook to fetch data from the Centrix API.
 *
 * @example
 * ```tsx
 * const {
 *   data, // the data fetched from the API
 *   isLoading, // true if the data is being fetched
 *   isError, // contains the error if the fetch failed
 *   mutate // function manage the data. more info: https://swr.vercel.app/docs/mutation
 * } = useCentrixApi({
 *   path: "/api/app/payment-request" // or any other
 *   parameters: {
 *     query: { // these parameters will be added to the query string of the url
 *       filter: "clientName",
 *     },
 *   swrOptions: {}, // see https://swr.vercel.app/docs/api.en-US#options
 *   shouldFetch: true, // if false, the hook will not fetch the data
 *   fetchAll: false, // if true, the hook will fetch all paginated data. this option should should only be used if endpoint supports pagination. default is false
 *   });
 *
 * if (isLoading) return <div>Loading...</div>; // handle loading state
 * if (isError) return <ErrorComponent apiError={isError} />; // handle error state
 * return (<div>{JSON.stringify(data)}</div>); // render the data
 *
 * ```
 */
export function useCentrixApi<P extends CentrixPathsByMethod<"get">>({
  path,
  parameters,
  swrOptions,
  shouldFetch = true,
  fetchAll = false,
}: UseCentrixApiArg<P>) {
  const { locale } = useRouter();
  const jsonFetcher = useJsonFetcherWithHeaders();
  const allPaginatedDataFetcher = useFetchAllPaginatedData();
  const [url, fetcher] = useMemo(() => {
    if (!fetchAll) {
      return [buildCentrixUrl({ path, parameters, locale }), jsonFetcher];
    }
    const newURL = new URL(buildCentrixUrl({ path, parameters, locale }));
    newURL.searchParams.delete("MaxResultCount");
    newURL.searchParams.delete("maxResultCount");
    newURL.searchParams.delete("SkipCount");
    newURL.searchParams.delete("skipCount");
    newURL.searchParams.set("fetchAll", "true"); // this will be removed by allFetcher. Here it's being used as a flag for the SWR key
    return [newURL.toString(), allPaginatedDataFetcher];
  }, [
    fetchAll,
    path,
    parameters,
    locale,
    allPaginatedDataFetcher,
    jsonFetcher,
  ]);

  const { data, error, isLoading, mutate, isValidating } = useSWR<
    ExtractSuccessType<P, "get">,
    APIError
  >(shouldFetch ? url : null, fetcher, swrOptions);

  return {
    data,
    isLoading,
    isError: error,
    isValidating,
    mutate,
  };
}
