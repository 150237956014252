import Image, { StaticImageData } from "next/image";
import { useRegion } from "core/hooks/useRegion";
import { getBrand } from "core/utils/getBrand";
import { useMemo } from "react";
import { useRouter } from "next/router";
import type { BrandCode, RegionCode } from "core/entities";
import { useTranslation } from "react-i18next";
import { I18nLocale } from "core/entities/brandDefs";
import { useBreakpoints } from "core/hooks/useBreakpoints";

// CRE
import CRELogoEnglishDesktop from "public/logos/cre/cre_en_desktop.png";
import CRELogoEnglishMobile from "public/logos/cre/cre_en_mobile.png";
import CRELogoFrenchDesktop from "public/logos/cre/cre_fr_desktop.png";
import CRELogoFrenchMobile from "public/logos/cre/cre_fr_mobile.png";
// PROC
import PROCLogoDefault from "public/logos/proc/proc_default_default.png";
import ProcLogoMobile from "public/logos/proc/proc_default_mobile.png";
// SPEEDYAUTO
import SpeedyAutoLogoEnDesktop from "public/logos/speedyauto/speedyauto_en_desktop.png";
import SpeedyAutoLogoFrDesktop from "public/logos/speedyauto/speedyauto_fr_desktop.png";
import SpeedyAutoLogoEnMobile from "public/logos/speedyauto/speedyauto_en_mobile.png";
import SpeedyAutoLogoFrMobile from "public/logos/speedyauto/speedyauto_fr_mobile.png";
// SPEEDYGLASS
import SpeedyGlassLogoDefault from "public/logos/speedyglass/speedyglass_default_default.png";
// NOV
import NovusGlassLogoDefault from "public/logos/novusglass/novusglass_default_default.png";
import NovusGlassLogoMobile from "public/logos/novusglass/novusglass_default_mobile.png";
// FIX
import FixAutoLogoDesktop from "public/logos/fixaut/fixaut_default_desktop.png";
import FixAutoLogoDesktopGermany from "public/logos/fixaut/fixaut_default_desktop_de.png";
import FixAutoLogoMobile from "public/logos/fixaut/fixaut_default_mobile.png";
// Default
import FixNetworkLogoDefault from "public/logos/default_default_default.webp";

type LogoConfig = {
  defaultLogo: StaticImageData;
  desktop?: StaticImageData;
  mobile?: StaticImageData;
};

type RegionLogoConfig = Partial<Record<I18nLocale, LogoConfig>> & {
  defaultLocale: LogoConfig;
};

type BrandLogoConfig = Partial<Record<RegionCode, RegionLogoConfig>> & {
  defaultRegion: RegionLogoConfig;
};

type BrandLogoMap = Record<BrandCode | "defaultBrand", BrandLogoConfig>;

const brandLogoConfigMap: BrandLogoMap = {
  defaultBrand: {
    defaultRegion: { defaultLocale: { defaultLogo: FixNetworkLogoDefault } },
  },

  fixaut: {
    de: {
      defaultLocale: {
        defaultLogo: FixAutoLogoDesktopGermany,
        mobile: FixAutoLogoMobile,
      },
    },
    defaultRegion: {
      defaultLocale: {
        defaultLogo: FixAutoLogoDesktop,
        mobile: FixAutoLogoMobile,
      },
    },
  },
  speedyglass: {
    defaultRegion: { defaultLocale: { defaultLogo: SpeedyGlassLogoDefault } },
  },
  speedyauto: {
    defaultRegion: {
      defaultLocale: {
        defaultLogo: SpeedyAutoLogoEnDesktop,
        mobile: SpeedyAutoLogoEnMobile,
      },
      fr: {
        defaultLogo: SpeedyAutoLogoFrDesktop,
        mobile: SpeedyAutoLogoFrMobile,
      },
    },
  },
  novusglass: {
    defaultRegion: {
      defaultLocale: {
        defaultLogo: NovusGlassLogoDefault,
        mobile: NovusGlassLogoMobile,
      },
    },
  },
  proc: {
    defaultRegion: {
      defaultLocale: { defaultLogo: PROCLogoDefault, mobile: ProcLogoMobile },
    },
  },
  cre: {
    defaultRegion: {
      defaultLocale: {
        defaultLogo: CRELogoEnglishDesktop,
        mobile: CRELogoEnglishMobile,
      },
      fr: { defaultLogo: CRELogoFrenchDesktop, mobile: CRELogoFrenchMobile },
    },
  },
} as const satisfies BrandLogoMap;

function determineLogoImage(
  config: BrandLogoMap,
  brand: BrandCode,
  region: RegionCode,
  locale: I18nLocale,
  deviceSizeKey: "desktop" | "mobile"
): StaticImageData {
  const brandConfig = config[brand] ?? config.defaultBrand;
  const regionConfig = brandConfig[region] ?? brandConfig.defaultRegion;
  const localeConfig = regionConfig[locale] ?? regionConfig.defaultLocale;
  const logoConfig = localeConfig[deviceSizeKey] ?? localeConfig.defaultLogo;
  return logoConfig;
}

export function useBrandLogoSrc(logoStyle?: "desktop" | "mobile") {
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:BrandLogo" });

  const { isDesktop } = useBreakpoints();
  const deviceSizeKey = logoStyle ?? isDesktop ? "desktop" : "mobile";
  const { locale } = useRouter();
  const region = useRegion();
  const { brandCode } = getBrand();
  const altText = t(`altText.${brandCode}`);
  const src: StaticImageData = useMemo(
    () =>
      determineLogoImage(
        brandLogoConfigMap,
        brandCode,
        region,
        locale as I18nLocale,
        deviceSizeKey
      ),
    [brandCode, region, locale, deviceSizeKey]
  );
  return { src, altText };
}

export function BrandLogo({
  logoStyle,
}: {
  logoStyle?: "desktop" | "mobile" | undefined;
}) {
  const { src, altText } = useBrandLogoSrc(logoStyle);
  return (
    <Image
      style={{ width: "100%", height: "auto" }}
      data-testid="brand-logo"
      placeholder="blur"
      src={src}
      alt={altText}
    />
  );
}
