import { useCentrixApi } from "./useCentrixApi";

export function usePermissionContext() {
  return useCentrixApi({
    path: "/api/shared-entities/permission-context",
    swrOptions: {
      revalidateIfStale: false,
    },
  });
}
