import { DocTranslationDto, TranslationKey } from "../entities";
/**
 * @returns a translation object in the current locale, otherwise defaults to the first available translation
 */
export function extractCurrentTranslation(
  translations: DocTranslationDto[],
  locale: TranslationKey
): DocTranslationDto | undefined {
  return (
    translations?.find(
      (currentTranslation) => currentTranslation?.transKey === locale
    ) || translations[0]
  );
}
