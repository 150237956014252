import { RegionCode } from "core/entities";
import { useRouter } from "next/router";
import { isValidRegion } from "core/utils/isValidRegion";
import { getBrand } from "../utils/getBrand";
import { useAvailableRegions } from "./useAvailableRegions";

export const REGION_KEY = "region";

export function useRegion(): RegionCode {
  const {
    query: { region: queryRegion },
  } = useRouter();
  const availableRegions = useAvailableRegions();
  const { brandCode } = getBrand();
  if (!brandCode) {
    throw new Error("useRegion hook requires a brand code");
  }
  const isQueryValid = isValidRegion(queryRegion, availableRegions);
  if (isQueryValid) {
    return queryRegion;
  }

  const storageKey = `user-storage.${brandCode}-${REGION_KEY}`;
  const storageRegion = JSON.parse(localStorage.getItem(storageKey) ?? '""');
  const isStorageRegionValid = isValidRegion(storageRegion, availableRegions);
  if (isStorageRegionValid) {
    return storageRegion;
  }

  if (availableRegions.includes("ca")) {
    return "ca";
  }
  const fallbackRegion = availableRegions[0];
  if (!fallbackRegion) {
    throw new Error(
      `no "availableRegions" configured for the brand ${brandCode}`
    );
  }

  return fallbackRegion;
}
