import { useAPIInfinite } from "core/hooks/useAPIInfinite";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import {
  EventArticleFiltersProperties,
  IEventArticleSummaryDto,
} from "../entities";

export function useEventArticleList(
  locale: string,
  filters: EventArticleFiltersProperties,
  maxResultCount: number = 10
) {
  const APIInfiniteReturn = useAPIInfinite<IEventArticleSummaryDto>({
    path: "/api/news-events/articles/Events/public",
    params: {
      locale,
      AvailableFromMax: endOfDay(new Date()).toUTCString(),
      AvailableToMin: startOfDay(new Date()).toUTCString(),
      ...filters,
    },
    maxResultCount,
  });

  return APIInfiniteReturn;
}
