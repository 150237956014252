import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import getConfig from "next/config";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import { CustomLink } from "core/components/CustomLink";
import { ExpandButton } from "core/components/MobileNav/ExpandButton";

const {
  COMMUNITY_IDEA_HREF,
  COMMUNITY_IDEA_TARGET = "_self",
  COMMUNITY_ISSUE_HREF,
  COMMUNITY_ISSUE_TARGET = "_self",
  COMMUNITY_RELEASE_NOTES_HREF,
  COMMUNITY_RELEASE_NOTES_TARGET = "_self",
} = getConfig()?.publicRuntimeConfig ?? {};

export function CommunityFeedback() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  function closeMenu() {
    setAnchorEl(null);
  }

  if (
    !COMMUNITY_IDEA_HREF &&
    !COMMUNITY_ISSUE_HREF &&
    !COMMUNITY_RELEASE_NOTES_HREF
  ) {
    return null;
  }

  return (
    <>
      <IconButton
        data-testid="community-improvements-menu-button"
        sx={{ color: "primary.contrastText" }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <MenuBookIcon color="inherit" />
      </IconButton>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={closeMenu}>
        <CommunityFeedbackContent />
      </Menu>
    </>
  );
}

export function CommunityFeedbackContent() {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:CommunityFeedback",
  });
  return (
    <>
      {(COMMUNITY_IDEA_HREF || COMMUNITY_ISSUE_HREF) && (
        <Divider textAlign="center">
          <Typography color="text.secondary" variant="overline">
            {t("communityFeedback")}
          </Typography>
        </Divider>
      )}
      {COMMUNITY_IDEA_HREF && (
        <ListItemButton
          target={COMMUNITY_IDEA_TARGET}
          component={COMMUNITY_IDEA_TARGET === "_blank" ? "a" : CustomLink}
          href={COMMUNITY_IDEA_HREF}
        >
          <ListItemText primary={t("submitIdea")} />
        </ListItemButton>
      )}
      {COMMUNITY_ISSUE_HREF && (
        <ListItemButton
          href={COMMUNITY_ISSUE_HREF}
          target={COMMUNITY_ISSUE_TARGET}
          component={COMMUNITY_ISSUE_TARGET === "_blank" ? "a" : CustomLink}
        >
          <ListItemText primary={t("submitIssue")} />
        </ListItemButton>
      )}
      {COMMUNITY_RELEASE_NOTES_HREF && (
        <>
          <Divider textAlign="center">
            <Typography color="text.secondary" variant="overline">
              {t("whatIsNew")}
            </Typography>
          </Divider>
          <ListItemButton
            href={COMMUNITY_RELEASE_NOTES_HREF}
            target={COMMUNITY_RELEASE_NOTES_TARGET}
            component={
              COMMUNITY_RELEASE_NOTES_HREF === "_blank" ? "a" : CustomLink
            }
          >
            <ListItemText primary={t("releaseNotes")} />
          </ListItemButton>
        </>
      )}
    </>
  );
}

export function MobileCommunityFeedback() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:CommunityFeedback",
  });

  if (
    !COMMUNITY_IDEA_HREF &&
    !COMMUNITY_ISSUE_HREF &&
    !COMMUNITY_RELEASE_NOTES_HREF
  ) {
    return null;
  }

  return (
    <>
      <ListItem
        secondaryAction={
          <ExpandButton
            open={open}
            onClick={() => {
              setOpen((currentOpenState) => !currentOpenState);
            }}
          />
        }
      >
        <ListItemText
          primary={t("communityFeedback")}
          primaryTypographyProps={{ fontWeight: "bold" }}
        />
      </ListItem>
      <Collapse in={open}>
        <List dense>
          {COMMUNITY_IDEA_HREF && (
            <ListItemButton
              target={COMMUNITY_IDEA_TARGET}
              component={COMMUNITY_IDEA_TARGET === "_blank" ? "a" : CustomLink}
              href={COMMUNITY_IDEA_HREF}
            >
              <ListItemText primary={t("submitIdea")} />
            </ListItemButton>
          )}
          {COMMUNITY_ISSUE_HREF && (
            <ListItemButton
              href={COMMUNITY_ISSUE_HREF}
              target={COMMUNITY_ISSUE_TARGET}
              component={COMMUNITY_ISSUE_TARGET === "_blank" ? "a" : CustomLink}
            >
              <ListItemText primary={t("submitIssue")} />
            </ListItemButton>
          )}
          {COMMUNITY_RELEASE_NOTES_HREF && (
            <ListItemButton
              href={COMMUNITY_RELEASE_NOTES_HREF}
              target={COMMUNITY_RELEASE_NOTES_TARGET}
              component={
                COMMUNITY_RELEASE_NOTES_HREF === "_blank" ? "a" : CustomLink
              }
            >
              <ListItemText primary={t("releaseNotes")} />
            </ListItemButton>
          )}
        </List>
      </Collapse>
      <Divider sx={{ my: 2 }} />
    </>
  );
}
