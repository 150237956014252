import { ReactNode } from "react";
import { useDoesUserExistInCurrentContext } from "core/hooks/useDoesUserExistInCurrentContext";
import { UnauthorizedContextPage } from "core/pages/UnauthorizedContextPage";
import { AvailableRoutes } from "resources/availableRoutes";
import { useRouter } from "next/router";
import { useExtensoToken } from "core/hooks/useExtensoToken";
import { StandardLayout } from "./StandardLayout";
import { ExtensoLayout } from "./ExtensoLayout";
import { UnauthorizedLayout } from "./UnauthorizedLayout";
import { SkipToMainContentLink } from "./SkipToMainContentLink";

export interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  const { pathname } = useRouter();
  const userExistsInCurrentContext = useDoesUserExistInCurrentContext();
  const { token: extensoToken } = useExtensoToken();

  const extensoRoute: AvailableRoutes = "/extenso-redirect";
  const isExtensoRoute = pathname === extensoRoute;
  const isExtenso = isExtensoRoute || Boolean(extensoToken);

  if (isExtenso) {
    return (
      <ExtensoLayout>
        {userExistsInCurrentContext ? children : <UnauthorizedContextPage />}
      </ExtensoLayout>
    );
  }
  if (!userExistsInCurrentContext) {
    return (
      <UnauthorizedLayout>
        <UnauthorizedContextPage />
      </UnauthorizedLayout>
    );
  }

  return (
    <>
      <SkipToMainContentLink />
      <StandardLayout>{children}</StandardLayout>
    </>
  );
}
