import { APIError } from "core/entities";
import { TFunction } from "react-i18next";

export function formatApiErrorMessage(
  apiError: APIError | undefined,
  t: TFunction
) {
  if (!apiError) {
    return t("Next:Core:Error");
  }
  const { status, info, message: genericErrorMessage } = apiError;
  const statusText = status ? `${status}: ` : "";
  const errorCode = info?.error?.code;
  const errorText =
    apiError.info?.error?.message ||
    (errorCode && t(errorCode)) ||
    genericErrorMessage ||
    "";
  return `${statusText}${errorText}`;
}
