import Typography, { TypographyProps } from "@mui/material/Typography";
import type { APIError } from "core/entities";
import { useTranslation } from "react-i18next";
import { formatApiErrorMessage } from "../utils/formatApiErrorMessage";

export interface ErrorComponentProps extends TypographyProps {
  apiError?: APIError;
  message?: string;
}

export function ErrorComponent({
  apiError,
  message,
  ...rest
}: ErrorComponentProps) {
  const { t } = useTranslation();
  if (message) {
    return (
      <Typography variant="h5" color="error.main" {...rest}>
        {message}
      </Typography>
    );
  }
  const apiErrorMessage = formatApiErrorMessage(apiError, t);
  return (
    <Typography variant="h5" color="error.main" {...rest}>
      {apiErrorMessage}
    </Typography>
  );
}
