import { useNavigate } from "core/hooks/useNavigate";
import { Loading } from "core/components/Loading";
import { useEffect } from "react";

export function HomePage() {
  const navigate = useNavigate(false);
  useEffect(() => {
    (async () => {
      await navigate("/");
    })();
  }, [navigate]);
  return <Loading />;
}
