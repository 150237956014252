import { ReactHookTagInput } from "core/components/ReactHookFormComponents/ReactHookTagInput";
import { ReactHookTextField } from "core/components/ReactHookFormComponents/ReactHookTextField";
import { useState, useLayoutEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useTranslationFields } from "./useTranslationFields";
import { DMSLanguageTabs } from "./DMSLanguageTabs";
import { determineSlideDirection } from "./determineSlideDirection";

export interface ContainerTranslationsFieldsProps {
  formType?: "LibraryForm" | "CategoryForm" | "SubCategoryForm";
}

/**
 * Adds { translations: IDocTranslationDto[] } to the current react-hook-form context
 * @param formType - the type of the parent form. Used to name translation keys
 */
export function ContainerTranslationsFields({
  formType = "LibraryForm",
}: ContainerTranslationsFieldsProps) {
  const { t } = useTranslation("DocumentManagementSystem");
  const { register } = useFormContext();
  const {
    currentLanguages: languages,
    selectedLanguage,
    previousLanguage,
    languageTabsProps,
  } = useTranslationFields("container");
  const [langInputHeight, setLangInputHeight] = useState(0);

  const containers = useRef<HTMLElement[]>([]);
  const observer = useRef(
    new ResizeObserver((entries) => {
      const boxHeight = entries?.reduce((max, current) => {
        const currentHeight = current?.contentRect?.height || 0;
        return currentHeight > max ? currentHeight : max;
      }, 0);
      setLangInputHeight(boxHeight);
    })
  );

  useLayoutEffect(() => {
    const resizeObserver = observer.current;
    containers.current?.forEach((el) => {
      if (!el) {
        return;
      }
      resizeObserver?.observe(el);
    });
    return () => {
      resizeObserver?.disconnect();
    };
  }, [observer, languages]);

  return (
    <Stack spacing={2}>
      <DMSLanguageTabs {...languageTabsProps} />
      <Box position="relative" sx={{ height: langInputHeight }}>
        {languages.map((transKey, index) => (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: transKey === selectedLanguage ? 1 : 0,
            }}
            key={`${transKey}`}
          >
            <input
              type="hidden"
              {...register(`translations.${transKey}.transKey`, {
                value: transKey,
              })}
            />
            <Slide
              appear={index !== 0}
              key={transKey}
              in={selectedLanguage === transKey}
              direction={determineSlideDirection(
                index,
                languages,
                previousLanguage,
                selectedLanguage
              )}
            >
              <Stack
                spacing={2}
                ref={(el: HTMLElement | null) => {
                  if (el !== null) {
                    containers.current[index] = el;
                  }
                }}
              >
                <ReactHookTextField
                  fullWidth
                  required
                  name={`translations.${transKey}.name`}
                  label={t(`Next:DocumentManagementSystem:${formType}.name`, {
                    locale: transKey,
                  })}
                />
                <ReactHookTextField
                  fullWidth
                  multiline
                  minRows={6}
                  name={`translations.${transKey}.description`}
                  label={t(
                    `Next:DocumentManagementSystem:${formType}.description`,
                    { locale: transKey }
                  )}
                  tOptions={{ locale: transKey }}
                />
                <ReactHookTagInput
                  fullWidth
                  name={`translations.${transKey}.tags`}
                  label={t(`Next:DocumentManagementSystem:${formType}.tags`, {
                    locale: transKey,
                  })}
                />
              </Stack>
            </Slide>
          </Box>
        ))}
      </Box>
    </Stack>
  );
}
