import { useMemo } from "react";
import {
  useViewableDocuments,
  UseViewableDocumentsOptions,
} from "features/DMS/hooks";
import { useRouter } from "next/router";
import { DMSWidgetListItemData, TranslationKey } from "features/DMS/entities";
import { useMenuItems } from "core/hooks/useMenuItems";
import { formatDocumentDataForWidget } from "./formatDocumentDataForWidget";

export function useDMSWidgetData(options?: UseViewableDocumentsOptions) {
  const { data, isError, isLoading } = useViewableDocuments(options);
  const { data: menuItems } = useMenuItems();

  const menuItemsMap = useMemo(() => {
    if (!Array.isArray(menuItems)) {
      return undefined;
    }
    const menuItemMapping = menuItems.reduce((mapping, menuItem) => {
      mapping.set(menuItem.id, menuItem.displayName);
      return mapping;
    }, new Map());
    return Object.fromEntries<string>(menuItemMapping);
  }, [menuItems]);

  const { locale } = useRouter();

  const { items = [], totalCount = 0 } = useMemo(() => {
    if (!data || !locale || !menuItemsMap) {
      return {};
    }
    const processedItems = data.items
      ?.map((item) =>
        formatDocumentDataForWidget(
          item,
          locale as TranslationKey,
          menuItemsMap
        )
      )
      .filter((entry): entry is DMSWidgetListItemData => Boolean(entry));
    return {
      items: processedItems,
      totalCount: data.totalCount,
    };
  }, [data, locale, menuItemsMap]);

  return {
    data,
    isError,
    isLoading,
    items,
    totalCount,
  };
}
