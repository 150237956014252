import { useRef, useCallback, DependencyList } from "react";

export function useDebouncedCallback<F extends (...args: any) => any>(
  fn: F,
  deps: DependencyList,
  delay: number
) {
  const timeout = useRef<NodeJS.Timeout | undefined>();

  const callbackFunction = useCallback(
    (...args: Parameters<F>) => {
      const setTimeoutCallback = () => {
        clearTimeout(timeout.current);
        fn(...args);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(setTimeoutCallback, delay);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, delay]
  );
  return callbackFunction;
}
