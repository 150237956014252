import { useFormatDate } from "core/hooks/useFormatDate";
import { useTranslation } from "react-i18next";
import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import IconButton from "@mui/material/IconButton";
import { CustomLink } from "core/components/CustomLink";
import { MyLinksIcon } from "features/MyLinks/components/MyLinksIcon";
import { getMyLinksHref } from "features/MyLinks/utils";
import { SearchResultEvent } from "../defs";
import { SearchItemTemplate } from "./SearchItemTemplate";

export function SearchItemEvent(props: SearchResultEvent) {
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:SearchItem" });
  const { id, title, blurb, isFavorited, dynamicData } = props;
  const formatDate = useFormatDate();
  const { startDate: startDateRaw, endDate: endDateRaw } = dynamicData;
  const [startDate, endDate] = [
    formatDate(startDateRaw),
    formatDate(endDateRaw),
  ];
  return (
    <SearchItemTemplate
      data-testid="search-item-event"
      dateLabel={t("eventDateLabel")}
      dateText={t("eventDate", { startDate, endDate })}
      secondaryAction={
        <IconButton
          LinkComponent={CustomLink}
          href={getMyLinksHref("Event", id)}
        >
          <KeyboardDoubleArrowRight color="primary" />
        </IconButton>
      }
      AvatarIcon={<MyLinksIcon itemType="Event" />}
      title={title}
      blurb={blurb}
      isFavorited={isFavorited}
    />
  );
}
