import { usePermissionChecker } from "core/hooks/usePermissionChecker";
import { useManageableLibraries } from "features/DMS/hooks";

export function useCanManageDMS() {
  const { data } = useManageableLibraries();
  const { hasSomePermissions } = usePermissionChecker();
  const canManageLibraryContent = data?.totalCount && data?.totalCount > 0;

  const hasLibraryCrud = hasSomePermissions([
    "Fixhub.DocLibraries.Create",
    "Fixhub.DocLibraries.Edit",
    "Fixhub.DocLibraries.Delete",
  ]);

  return canManageLibraryContent || hasLibraryCrud;
}
