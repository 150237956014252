import {
  Controller,
  Control,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import Box, { BoxProps } from "@mui/material/Box";

export interface ReactHookCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<Partial<FormControlLabelProps>, "control" | "defaultValue"> {
  name: TName;
  label: string;
  control?: Control<TFieldValues>;
  testId?: string;
  onChangeCallback?: () => void;
  boxProps?: BoxProps;
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

export function ReactHookCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  label,
  control,
  sx,
  labelPlacement = "start",
  onChange,
  onChangeCallback,
  testId = "react-hook-checkbox",
  rules,
  boxProps,
  ...props
}: ReactHookCheckboxProps<TFieldValues, TName>) {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box {...boxProps}>
          <FormControlLabel
            label={label}
            labelPlacement={labelPlacement}
            {...props}
            sx={{ m: 0, color: error ? "error.main" : "text.primary", ...sx }}
            control={
              <Checkbox
                {...field}
                inputRef={field.ref}
                data-testid={testId}
                onChange={(e, checked) => {
                  if (typeof onChange === "function") {
                    const result = onChange(e, checked);
                    field.onChange(result);
                  } else {
                    field.onChange(e, checked);
                  }
                  if (typeof onChangeCallback === "function") {
                    onChangeCallback();
                  }
                }}
                checked={field.value}
              />
            }
          />
          {error && (
            <FormHelperText sx={{ color: "error.main" }}>
              {error?.message}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
}
