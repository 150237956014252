import { ReactNode } from "react";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { AvailableRoutes } from "resources/availableRoutes";

export interface LogoutPageBypassProps {
  children: ReactNode;
  Component: AppProps["Component"];
  pageProps: AppProps["pageProps"];
}

export function LayoutBypass({
  children,
  Component,
  pageProps,
}: LogoutPageBypassProps) {
  const { pathname } = useRouter();
  const bypassPathnames: AvailableRoutes[] = [
    "/extenso-redirect",
    "/signin-oidc",
    "/logout",
  ];

  if (bypassPathnames.includes(pathname as AvailableRoutes)) {
    return <Component {...pageProps} />;
  }

  return <>{children}</>;
}
