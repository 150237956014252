import { ReactNode, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { FilterContext } from "core/context/FilterContext";
import { useScrollTopOnNavigation } from "core/hooks/useScrollTopOnNavigation";
import { ComponentErrorBoundary } from "../ComponentErrorBoundary";
import { Footer } from "../Footer";
import { GlobalHeader } from "../GlobalHeader";

interface StandardLayoutProps {
  children: ReactNode;
}

export function StandardLayout({ children }: StandardLayoutProps) {
  const filterContextValue = useState(false);
  const containerRef = useScrollTopOnNavigation();
  const [isFilterOn] = filterContextValue;
  return (
    <FilterContext.Provider value={filterContextValue}>
      <ComponentErrorBoundary>
        <GlobalHeader />
      </ComponentErrorBoundary>
      <Stack
        ref={containerRef}
        bgcolor="background.default"
        sx={{
          flex: 1,
          filter: isFilterOn ? "brightness(0.75)" : undefined,
          transition: "filter 500ms ease",
          tabIndex: 0,
        }}
      >
        <Box
          id="main-content"
          sx={{ flex: 1, paddingBlockStart: 2, paddingBlockEnd: 4 }}
        >
          {children}
        </Box>
        <ComponentErrorBoundary>
          <Footer />
        </ComponentErrorBoundary>
      </Stack>
    </FilterContext.Provider>
  );
}
