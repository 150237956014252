import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useToaster } from "core/hooks/useToaster";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { useCategories } from "../hooks";

interface CategoryDeleteProps {
  cancelOnClick: () => void;
  id: string;
  docLibraryId: string;
  canManageLibraryContents: boolean;
}

export function CategoryDelete({
  cancelOnClick = () => {},
  id,
  docLibraryId,
  canManageLibraryContents,
}: CategoryDeleteProps) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:CategoryDelete",
  });
  const { successToast, errorToast } = useToaster();
  const { mutate } = useCategories({
    parentId: docLibraryId,
    mode: "manage",
    canManageLibraryContents,
  });
  const centrixFetch = useCentrixFetch();
  const errorMessage = t("deleteError");
  const successMessage = t("deleteSuccess");

  async function onDeleteClick() {
    const deleteResponse = await centrixFetch({
      method: "delete",
      path: "/api/app/doc-category/{id}",
      parameters: { path: { id } },
    });
    if (!deleteResponse.ok) {
      errorToast(errorMessage);
      return;
    }
    await mutate();
    successToast(successMessage);
    cancelOnClick();
  }
  return (
    <Box>
      <Typography
        variant="h5"
        mx="auto"
        mb={4}
        textAlign="center"
        maxWidth="65ch"
      >
        {t("warningMessage")}
      </Typography>
      <Typography
        variant="h5"
        mx="auto"
        mb={4}
        textAlign="center"
        maxWidth="65ch"
      >
        {t("confirmMessage")}
      </Typography>
      <Stack spacing={2} direction="row" justifyContent="center">
        <Button
          variant="outlined"
          color="warning"
          onClick={cancelOnClick}
          size="large"
        >
          {t("cancelButton")}
        </Button>
        <Button
          variant="contained"
          color="error"
          size="large"
          onClick={onDeleteClick}
        >
          {t("deleteButton")}
        </Button>
      </Stack>
    </Box>
  );
}
