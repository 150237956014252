import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import { StyledTabs } from "core/components/StyledTabs";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import { useFormContext } from "react-hook-form";
import { MouseEventHandler, SyntheticEvent } from "react";
import { TranslationKey } from "features/DMS/entities";

export interface DMSLanguageTabsProps {
  mode: "container" | "document";
  handleTabChange: (event: SyntheticEvent, value: TranslationKey) => void;
  currentLanguages: TranslationKey[];
  tabValue: TranslationKey;
  unselectedLanguageOptions: TranslationKey[];
  newLanguageOnClick: MouseEventHandler<HTMLDivElement>;
  removeLanguageOnClick: MouseEventHandler<HTMLButtonElement>;
  menuAnchor: MenuProps["anchorEl"];
  handleLanguageSelectMenuClose: (transKey: TranslationKey) => void;
}

export function DMSLanguageTabs({
  mode,
  handleTabChange,
  tabValue,
  currentLanguages,
  unselectedLanguageOptions,
  newLanguageOnClick,
  removeLanguageOnClick,
  menuAnchor,
  handleLanguageSelectMenuClose,
}: DMSLanguageTabsProps) {
  const { t } = useTranslation("DocumentManagementSystem");
  const { watch } = useFormContext();
  const isDefault = watch(`translations.${tabValue}.isDefault`);
  const removeLanguageVisible =
    mode === "document"
      ? !isDefault && currentLanguages.length > 1
      : currentLanguages.length > 1;
  return (
    <Box width="100%">
      <Menu
        id="additional-language-menu"
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleLanguageSelectMenuClose}
      >
        {unselectedLanguageOptions.map((transKey) => (
          <MenuItem
            key={transKey}
            onClick={() => {
              handleLanguageSelectMenuClose(transKey);
            }}
          >
            {t(`Next:Core:LanguageCodes.${transKey}`)}
          </MenuItem>
        ))}
      </Menu>
      <Stack direction="row">
        <StyledTabs value={tabValue} onChange={handleTabChange}>
          {currentLanguages.map((transKey) => (
            <Tab
              key={transKey}
              label={t(`Next:Core:LanguageCodes.${transKey}`)}
              value={transKey}
            />
          ))}
          {unselectedLanguageOptions.length > 0 && (
            <Tab
              onClick={newLanguageOnClick}
              label={<AddIcon />}
              sx={{
                borderRadius: ({ shape: { borderRadius: br } }) =>
                  `${br}px ${br}px 0 0`,
                "&:hover": {
                  backgroundColor: ({ palette }) => palette.action.hover,
                },
              }}
            />
          )}
        </StyledTabs>
        <Fade in={removeLanguageVisible}>
          <Stack direction="row" flex={1} justifyContent="flex-end">
            <Button color="error" onClick={removeLanguageOnClick}>
              {t("Next:DocumentManagementSystem:LanguageTabs.RemoveLanguage")}
            </Button>
          </Stack>
        </Fade>
      </Stack>
      <Divider />
    </Box>
  );
}
