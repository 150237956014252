import { ALL_VALUE } from "config";

export function filterShopOptionsBySelectedBrandsAndRegions(
  selectedBrands: string[],
  selectedRegions: string[],
  shopOptions: { label: string; value: string }[],
  shopBrandRegionMap: Record<
    string,
    {
      brand: string;
      region: string;
    }
  >
): { label: string; value: string }[] {
  const isBrandAllOrNone =
    selectedBrands.length === 0 ||
    selectedBrands.some((val) => val === ALL_VALUE);
  const isRegionsAllOrNone =
    selectedRegions.length === 0 ||
    selectedRegions.some((val) => val === ALL_VALUE);
  if (isBrandAllOrNone && isRegionsAllOrNone) {
    return shopOptions;
  }
  return shopOptions.filter(({ value: shopOptionValue }) => {
    if (shopOptionValue === ALL_VALUE) {
      return true;
    }
    const shopInfo = shopBrandRegionMap[shopOptionValue];
    if (!shopInfo) {
      return false;
    }
    const { brand: shopBrand, region: shopRegion } = shopInfo;
    const isBrandValid =
      selectedBrands.includes(ALL_VALUE) || selectedBrands.includes(shopBrand);
    const isRegionValid =
      selectedRegions.includes(ALL_VALUE) ||
      selectedRegions.includes(shopRegion);
    return isBrandValid && isRegionValid;
  });
}
