import { ReactNode } from "react";
import Box from "@mui/material/Box";
import { useScrollTopOnNavigation } from "core/hooks/useScrollTopOnNavigation";

interface ExtensoLayoutProps {
  children: ReactNode;
}

export function ExtensoLayout({ children }: ExtensoLayoutProps) {
  const containerRef = useScrollTopOnNavigation();
  return (
    <Box flex={1} ref={containerRef} px={2} pt={1} pb={2}>
      {children}
    </Box>
  );
}
