import { useCallback, useEffect, useRef } from "react";

/**
 * Custom hook that sets up a timeout and provides functions to reset and clear the timeout.
 *
 * @param callback - The callback function to be executed when the timeout expires.
 * @param delayInMilliseconds - The delay in milliseconds before the callback is invoked.
 * @returns An object containing the `reset` and `clear` functions.
 */
export function useTimeout(callback: Function, delayInMilliseconds: number) {
  const callbackRef = useRef(callback);
  const timeoutRef: { current: ReturnType<typeof setTimeout> | null } =
    useRef(null);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(
      () => callbackRef.current(),
      delayInMilliseconds
    );
  }, [delayInMilliseconds]);

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delayInMilliseconds, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
}
