import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { NotificationDot } from "features/Notifications/NotificationDot";
import { CustomLink } from "../CustomLink";
import { useMobileMenuItems } from "../MobileNav/useMobileMenuItems";

export function FooterCategories() {
  const { menuItems } = useMobileMenuItems("Footer");
  const categoryProps: TypographyProps["sx"] = {
    display: "block",
    variant: "body2",
    textTransform: "uppercase",
    fontWeight: 700,
    textAlign: "left",
  };
  return (
    <>
      {menuItems.map(({ url, displayName, id, categories: links, target }) => (
        <Box key={id}>
          {url ? (
            <CustomLink
              href={url}
              target={target ?? undefined}
              {...categoryProps}
            >
              {displayName} <NotificationDot mode="self" id={id} />
            </CustomLink>
          ) : (
            <Typography {...categoryProps}>{displayName}</Typography>
          )}

          <Divider sx={{ mb: 2, mt: 1 }} />
          <Stack gap={1} flexWrap="wrap" maxHeight="100px">
            {links.map(({ displayName: linkName, url: linkUrl, id: linkId }) =>
              linkUrl ? (
                <CustomLink variant="body2" href={linkUrl} key={linkId}>
                  {linkName} <NotificationDot mode="self" id={linkId} />
                </CustomLink>
              ) : (
                <Typography variant="body2" key={linkId}>
                  {linkName}
                </Typography>
              )
            )}
          </Stack>
        </Box>
      ))}
    </>
  );
}
