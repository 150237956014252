import Typography from "@mui/material/Typography";
import { useToaster } from "core/hooks/useToaster";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { CategoryFormValues } from "../entities";
import { useCategories, useDMSFormErrorHandler } from "../hooks";
import { DmsCategoryForm, defaultValues } from "./DmsCategoryForm";

interface SubCategoryAddProps {
  cancelOnClick: () => void;
  parentId: string;
  docLibraryId: string;
  canManageLibraryContents: boolean;
}

export function SubCategoryAdd({
  cancelOnClick,
  parentId,
  docLibraryId,
  canManageLibraryContents,
}: SubCategoryAddProps) {
  const { t } = useTranslation("DocumentManagementSystem");

  const formMethods = useForm<CategoryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;
  const { mutate } = useCategories({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });
  const centrixFetch = useCentrixFetch();
  const { successToast, errorToast } = useToaster();
  const { errorHandler } = useDMSFormErrorHandler(formMethods, "CategoryForm");
  const successMessage = t(
    "Next:DocumentManagementSystem:SubCategoryAdd.POSTSuccess"
  );
  const errorMessage = t(
    "Next:DocumentManagementSystem:SubCategoryAdd.POSTError"
  );

  async function handleFormSubmit({
    translations: translationsObject,
  }: CategoryFormValues) {
    const translations = Object.values(translationsObject).filter(
      (entry): entry is typeof entry & { name: string } =>
        typeof entry.name === "string"
    );
    const categoryResponse = await centrixFetch({
      method: "post",
      path: "/api/app/doc-category",
      body: { parentId, docLibraryId, translations },
    });
    if (!categoryResponse.ok) {
      errorToast(errorMessage);
      return;
    }
    await mutate();
    successToast(successMessage);
    cancelOnClick();
  }

  function clearOnClick() {
    formMethods.reset(defaultValues);
  }

  return (
    <>
      <Typography variant="h3" textAlign="center" color="primary.main" my={3}>
        {t("Next:DocumentManagementSystem:SubCategoryAdd.title")}
      </Typography>
      <DmsCategoryForm
        formMethods={formMethods}
        onSubmit={handleSubmit(handleFormSubmit, errorHandler)}
        cancelOnClick={cancelOnClick}
        clearOnClick={clearOnClick}
        mode="add"
        formType="SubCategoryForm"
        isSubmitting={isSubmitting}
      />
    </>
  );
}
