import { useState } from "react";
import List from "@mui/material/List";
import LinearProgress from "@mui/material/LinearProgress";
import { ErrorComponent } from "../ErrorComponent";
import { useMobileMenuItems } from "./useMobileMenuItems";
import { MobileNavRootMenuItem } from "./MobileNavRootMenuItem";

export function MobileNavMenuItems() {
  const [openId, setOpenId] = useState<null | string>(null);
  const { isError, isLoading, menuItems } = useMobileMenuItems();
  if (isError) {
    return <ErrorComponent apiError={isError} />;
  }
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <List>
      {menuItems.map(
        ({ categories, displayName, id, libraries, target, url }) => (
          <MobileNavRootMenuItem
            key={id}
            categories={categories}
            displayName={displayName}
            id={id}
            libraries={libraries}
            open={openId === id}
            target={target ?? undefined}
            url={url ?? undefined}
            onExpandClick={() => {
              if (openId === id) {
                setOpenId(null);
                return;
              }
              setOpenId(id);
            }}
          />
        )
      )}
    </List>
  );
}
