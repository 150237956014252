import { useMemo } from "react";
import { ALL_VALUE } from "config";
import { useTranslation } from "react-i18next";
import { components } from "resources/api-schema";
import { useCentrixApi } from "./useCentrixApi";

type FixnRoleType =
  components["schemas"]["Fixnetwork.SharedEntities.Shared.FixnRoleType"];

export function useRoleOptions(type: FixnRoleType) {
  const { data, isError, isLoading } = useCentrixApi({
    path: "/api/app/role",
    parameters: { query: { type } },
  });
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:useRoleOptions",
  });
  const allLabel =
    type === "Corporate" ? t("allCorporateRoles") : t("allShopRoles");
  const roleOptions = useMemo(() => {
    const options =
      data
        ?.map(({ id, name }) => ({ label: name, value: id }))
        .filter((entry): entry is { label: string; value: string } =>
          Boolean(entry.label && entry.value)
        ) || [];
    return [{ label: allLabel, value: ALL_VALUE }, ...options];
  }, [data, allLabel]);

  const idRoleMap =
    data?.reduce<Record<string, string>>((acc, entry) => {
      const id = entry?.id;
      const name = entry?.name;
      if (typeof id !== "string" || typeof name !== "string") {
        return acc;
      }
      acc[id] = name;
      return acc;
    }, {}) || {};

  return { data, isError, isLoading, roleOptions, idRoleMap };
}
