import { API_URL } from "config";

export function APIFetch(path: string, options: RequestInit) {
  const currentHeaders = options.headers as Record<string, string>;
  if (!currentHeaders.Authorization) {
    throw new Error("Authorization header required");
  }

  const url = new URL(path, API_URL);
  return fetch(url.href, options);
}
