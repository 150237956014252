import { buildQueryString } from "core/utils/buildQueryString";
import { getBrandCode } from "core/utils/getBrandCode";
import { useBrandRegionCode } from "core/hooks/useBrandRegionCode";

export function useDmsDocumentHref(
  fileId?: string | null | undefined,
  fileName?: string | null | undefined,
  documentLink?: string | null | undefined
) {
  const regionCode = useBrandRegionCode();
  const brandCode = getBrandCode();
  const queryString = buildQueryString({
    brandCode,
    regionCode,
    fileId,
  });
  if (documentLink) {
    return documentLink;
  }
  if (!fileName || !fileId) {
    return "";
  }
  return `/api/dms-document/${encodeURIComponent(fileName)}?${queryString}`;
}
