import { useMemo } from "react";
import { getBrandCode } from "core/utils/getBrandCode";
import { useToken } from "./useToken";
import { useBrandRegionCode } from "./useBrandRegionCode";

export function useAPIHeaders() {
  const brand = getBrandCode();
  const brandRegionCode = useBrandRegionCode();
  const token = useToken();

  const headers = useMemo(() => {
    const initialHeaders: HeadersInit = {
      "x-fixnetwork-brand": brand ?? "",
      "x-fixnetwork-region": brandRegionCode ?? "",
    };

    if (token) {
      initialHeaders.Authorization = `Bearer ${token}`;
    }
    return initialHeaders;
  }, [brand, brandRegionCode, token]);

  return headers;
}
