import { ComponentProps } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import List, { ListProps } from "@mui/material/List";
import { useFormatDate } from "core/hooks/useFormatDate";
import { WidgetTabValues, DMSWidgetListItemData } from "features/DMS/entities";
import { useTranslation } from "react-i18next";
import { DMSWidgetLinkListItem } from "./DMSWidgetLinkListItem";
import { DMSWidgetFileListItem } from "./DMSWidgetDocumentFileListItem";

export interface DMSWidgetListProps extends ListProps {
  isLoading: boolean;
  documents: DMSWidgetListItemData[];
  selectedTab: WidgetTabValues;
}

export function DMSWidgetList({
  documents,
  isLoading,
  selectedTab,
  sx,
}: DMSWidgetListProps) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:DMSWidgetDocumentList",
  });
  const dateLabel = t(`dateLabel.${selectedTab}`);
  const formatDate = useFormatDate();
  if (isLoading) {
    return <LinearProgress sx={{ mt: 2, display: "block" }} />;
  }
  return (
    <List disablePadding sx={sx}>
      {documents.map((document) => {
        const dateText = formatDate(document[selectedTab] as string);
        const listItemSx: ComponentProps<typeof DMSWidgetList>["sx"] = {
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        };

        const { id, parents, tags, name } = document;

        if (document.type === "link") {
          return (
            <DMSWidgetLinkListItem
              key={id}
              id={id}
              title={name}
              tags={tags}
              parents={parents}
              sx={listItemSx}
              dateLabel={dateLabel}
              dateText={dateText}
              documentLink={document.documentLink}
            />
          );
        }

        return (
          <DMSWidgetFileListItem
            key={id}
            id={id}
            title={name}
            tags={tags}
            parents={parents}
            sx={listItemSx}
            fileId={document.fileId}
            fileName={document.fileName}
            dateLabel={dateLabel}
            dateText={dateText}
          />
        );
      })}
    </List>
  );
}
