import { useMemo } from "react";
import { useRouter } from "next/router";
import { useCentrixApi } from "./useCentrixApi";

interface ILanguageInfo {
  cultureName?: string | undefined;
  uiCultureName?: string | undefined;
  displayName?: string | undefined;
  flagIcon?: string | undefined;
}

/**
 * A hook that returns an array of available locales.
 * Intersects the data returned from "/api/app/available-languages" API endpoint
 * and the locales configured in next.config.js.
 * @returns An array of available locales.
 */
export function useAvailableLocales() {
  const { data, isError, isLoading } = useCentrixApi({
    path: "/api/app/available-languages",
    swrOptions: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });
  const { locales } = useRouter();
  const availableLocales = useMemo(() => {
    if (!data || !locales) {
      return [];
    }
    return data
      .filter(
        (language): language is WithRequired<ILanguageInfo, "cultureName"> =>
          Boolean(
            language.cultureName && locales.includes(language.cultureName)
          )
      )
      .map((language) => language.cultureName);
  }, [locales, data]);
  return { data, isError, isLoading, availableLocales };
}
