// https://swr.vercel.app/docs/error-handling
// Throws an error if the response is not OK
// with the body of the response attached to the error object in the "info" property.
export async function validateResponse(
  res: Response,
  errorMessage = "An error occurred while fetching the data."
): Promise<void> {
  if (!res.ok) {
    const error: Error & { info?: any; status?: number } = new Error(
      errorMessage
    );
    // Attach extra info to the error object.
    try {
      const isJson = res.headers.get("content-type")?.includes("json");
      const info = isJson ? await res.json() : await res.text();
      error.info = info;
    } catch {
      error.info = null;
    }
    error.status = res.status;
    throw error;
  }
}
