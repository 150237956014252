import { FixHubPermissions } from "resources/permissions";

export const BRAND_PERMISSION_CONTEXT_KEY = "brand" as const;
export const REGION_PERMISSION_CONTEXT_KEY = "region" as const;
export const DEPARTMENT_PERMISSION_CONTEXT_KEY = "department" as const;

export type ContextKey =
  | typeof BRAND_PERMISSION_CONTEXT_KEY
  | typeof REGION_PERMISSION_CONTEXT_KEY
  | typeof DEPARTMENT_PERMISSION_CONTEXT_KEY;
export interface ContextValue {
  key: ContextKey;
  value: string;
}
export interface PermissionContext {
  context: ContextValue[];
  permissions: FixHubPermissions[];
}
