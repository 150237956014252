import { useRouter } from "next/router";
import { useAPIInfinite } from "core/hooks/useAPIInfinite";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import {
  INewsArticleSummaryDto,
  NewsArticleFilterFormValues,
} from "../entities";

export function useNewsArticlePublicList(
  filters: NewsArticleFilterFormValues,
  maxResultCount: number = 10
) {
  const { locale } = useRouter();
  const {
    data,
    items,
    totalCount,
    isLoadingInitialData,
    isLoadingMore,
    isError,
    mutate,
    currentCount,
    loadMore,
    showLoadMore,
  } = useAPIInfinite<INewsArticleSummaryDto>({
    path: "/api/news-events/articles/News/public",
    params: {
      locale,
      AvailableFromMax: endOfDay(new Date()).toUTCString(),
      AvailableToMin: startOfDay(new Date()).toUTCString(),
      ...filters,
    },
    maxResultCount,
  });
  return {
    data,
    items,
    totalCount,
    isLoadingInitialData,
    isLoadingMore,
    isError,
    mutate,
    currentCount,
    loadMore,
    showLoadMore,
  };
}
