import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { NotificationDot } from "features/Notifications/NotificationDot";
import { CustomLink } from "../CustomLink";
import { CategoryMenuItem } from "./defs";

export function MobileNavCategoryMenuItem({
  id,
  displayName,
  url,
  target,
  links,
}: CategoryMenuItem) {
  return (
    <List dense>
      {url ? (
        <ListItem disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            component={CustomLink}
            target={target ?? undefined}
            href={url}
          >
            <ListItemText
              primary={
                <>
                  {displayName} <NotificationDot id={id} mode="self" />
                </>
              }
              primaryTypographyProps={{
                fontWeight: "bold",
              }}
            />
          </ListItemButton>
        </ListItem>
      ) : (
        <ListItem sx={{ pl: 4 }}>
          <ListItemText
            primary={displayName}
            primaryTypographyProps={{ fontWeight: "bold" }}
          />
        </ListItem>
      )}
      {links.map((link) => (
        <ListItem disablePadding key={link.id}>
          <ListItemButton
            sx={{ paddingLeft: 6 }}
            component={CustomLink}
            href={link.url}
            target={link.target || undefined}
          >
            <ListItemText
              primary={
                <>
                  {link.displayName}{" "}
                  <NotificationDot id={link.id} mode="self" />
                </>
              }
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
