import { PageId, ItemType } from "core/entities";
import { useCentrixFetch } from "./useCentrixFetch";

export interface UserLog {
  id: string;
  date: string;
  type: ItemType;
  entityId?: string;
  url?: string;
}

export interface UserLogStorage {
  pageViews: Record<string, number>;
  log: UserLog[];
}

export function useUserLog() {
  const centrixFetch = useCentrixFetch();

  async function addLog<T extends ItemType>(
    type: T,
    id: T extends "Page" ? PageId : string
  ) {
    const createLogResponse = await centrixFetch({
      method: "post",
      path: "/api/app/user-logs",
      body: { itemType: type, itemId: id },
    });
    if (!createLogResponse.ok) {
      // eslint-disable-next-line no-console
      console.error(`failed to log itemType:${type}:${id}`);
    }
  }
  return {
    addLog,
  };
}
