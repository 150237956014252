import { Brand, BrandCode } from "core/entities";
import { getBrandCode } from "core/utils/getBrandCode";
import BrandMap from "resources/BrandMap";

/**
 * Gets the brand information from the brandCode. If no brandCode is supplied, it will attempt to get the brandCode from the window location.
 * @param brandCode ex. "proc" | "fixaut" | "novusglass"
 */
export function getBrand(brandCode?: BrandCode): Brand {
  if (brandCode) {
    const brand = BrandMap.get(brandCode);
    if (!brand) {
      throw new Error(`Brand ${brandCode} not found`);
    }
    return brand;
  }
  const windowBrandCode = getBrandCode();
  if (!windowBrandCode) {
    throw new Error(
      `no brandCode supplied and could not determine the brandCode from the window location`
    );
  }
  const brand = BrandMap.get(windowBrandCode);
  if (!brand) {
    throw new Error(`Brand ${brandCode} not found`);
  }
  return brand;
}
