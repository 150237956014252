import { useRouter } from "next/router";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const localeLoadingMap = {
  en: "Loading",
  fr: "Chargement",
  es: "Cargando",
  "de-DE": "Laden",
  hi: "लोड हो रहा है",
  it: "Caricamento",
  sk: "Načítavanie",
  sl: "Nalaganje",
  tr: "Yükleniyor",
  ar: "جار التحميل",
};
export function ApplicationLoadingDisplay({ locale }: { locale?: string }) {
  const { locale: routerLocale } = useRouter();
  const actualLocale = locale || routerLocale;
  const message =
    actualLocale &&
    localeLoadingMap[actualLocale as keyof typeof localeLoadingMap];
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        height: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        {message && (
          <Typography variant="h3" color="primary">
            {message}
          </Typography>
        )}
        <CircularProgress />
      </Stack>
    </Box>
  );
}
