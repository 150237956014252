import { useUserPermissionContextsInCurrentBrandRegion } from "./useUserPermissionContextsInCurrentBrandRegion";

export function useDoesUserExistInCurrentContext() {
  const currentPermissionContexts =
    useUserPermissionContextsInCurrentBrandRegion();
  return Boolean(
    currentPermissionContexts &&
      Array.isArray(currentPermissionContexts) &&
      currentPermissionContexts.length > 0
  );
}
