import { BrandCode } from "core/entities";
import { isBrowser } from "config";
import { getBrandCodeFromHost } from "./getBrandCodeFromHost";

export function getBrandCode(url?: string): BrandCode | undefined {
  if (url) {
    try {
      const urlObject = new URL(url);
      return getBrandCodeFromHost(urlObject.hostname);
    } catch {
      return undefined;
    }
  }
  if (!isBrowser) {
    return undefined;
  }
  const windowHostname = window?.location.hostname;
  return getBrandCodeFromHost(windowHostname);
}
