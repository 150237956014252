/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Button,
  Paper,
  Stack,
  ThemeOptions,
  useTheme,
} from "@mui/material";

export function CustomThemePicker({
  setCustomTheme,
}: {
  setCustomTheme: (theme: ThemeOptions) => void;
}) {
  const theme = useTheme();
  return (
    <Paper
      elevation={24}
      sx={{ position: "fixed", bottom: "2rem", right: "2rem", zIndex: 1000 }}
    >
      <Box
        p={2}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const primary = formData.get("primary-color") as string;
          const secondary = formData.get("secondary-color") as string;
          const background = formData.get("background-color") as string;
          const paper = formData.get("paper-color") as string;
          setCustomTheme({
            palette: {
              primary: { main: primary },
              secondary: { main: secondary },
              background: { default: background, paper },
            },
          });
        }}
      >
        <Stack gap={2}>
          <Box sx={{ display: "grid", gap: 2, gridTemplateColumns: "1fr 1fr" }}>
            <label htmlFor="primary-color">Primary Color</label>
            <input
              name="primary-color"
              id="primary-color"
              type="color"
              defaultValue={theme.palette.primary.main}
            />
            <label htmlFor="secondary-color">Secondary Color</label>
            <input
              name="secondary-color"
              id="secondary-color"
              type="color"
              defaultValue={theme.palette.secondary.main}
            />
            <label htmlFor="background-color">Background Color</label>
            <input
              name="background-color"
              id="background-color"
              type="color"
              defaultValue={theme.palette.background.default}
            />
            <label htmlFor="paper-color">Paper Color</label>
            <input
              name="paper-color"
              id="paper-color"
              type="color"
              defaultValue={theme.palette.background.paper}
            />
          </Box>
          <Button variant="contained" type="submit" color="primary">
            Update Theme
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
}
