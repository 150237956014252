import { useState } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ListItemButton from "@mui/material/ListItemButton";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import { NotificationDot } from "features/Notifications/NotificationDot";
import { useMenuItems } from "core/hooks/useMenuItems";
import { CustomLink } from "../CustomLink";

export interface LibraryLinksProps {
  libraries: ReturnType<typeof useMenuItems>["menuItems"][number]["libraries"];
  numberOfInitiallyVisibleLinks: number;
}
export const testIds = {
  seeAllLink: "see-all-link",
};

export function LibraryLinks({
  libraries,
  numberOfInitiallyVisibleLinks,
}: LibraryLinksProps) {
  const { t } = useTranslation("Fixhub");
  const [isExpanded, setIsExpanded] = useState(false);
  const visibleLibraries = isExpanded
    ? libraries
    : libraries.slice(0, numberOfInitiallyVisibleLinks);
  const isShowAllButtonVisible =
    !isExpanded && libraries.length > numberOfInitiallyVisibleLinks;

  return (
    <List sx={{ width: "24ch" }}>
      <ListItem disableGutters>
        <Typography variant="button" textAlign="center">
          {t("Next:Core:LibraryLinks.title")}
        </Typography>
      </ListItem>
      <Divider />
      <TransitionGroup>
        {visibleLibraries.map(({ currentTranslation, id }) => (
          <Collapse key={id}>
            <ListItem disableGutters>
              <CustomLink
                href={`/dms/library/${id}`}
                underline="none"
                color="primary.dark"
              >
                {currentTranslation?.name}
                <NotificationDot mode="parent" id={id!} />
              </CustomLink>
            </ListItem>
          </Collapse>
        ))}
      </TransitionGroup>
      <Collapse in={isShowAllButtonVisible}>
        <ListItem
          disableGutters
          sx={{
            p: 0,
            my: 1,
            borderBlockStart: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <ListItemButton
            onClick={() => setIsExpanded(true)}
            sx={{ justifyContent: "center" }}
          >
            <KeyboardDoubleArrowDownIcon color="primary" />
          </ListItemButton>
        </ListItem>
      </Collapse>
    </List>
  );
}
