import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, ButtonProps } from "@mui/material";
import Paper, { PaperProps } from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useInterval } from "core/hooks/useInterval";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Image from "next/image";

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  position: "absolute",
  height: "100%",
  top: 0,
  zIndex: 1,
  color: theme.palette.primary.contrastText,
  "& > *": { transition: "scale 0.1s ease" },
  "&:hover > *": { scale: "1.2" },
  "&:focus-visible": { outline: "1px solid white" },
}));

type DotProps = { active: boolean };

const Dot = styled("button", {
  shouldForwardProp: (propName) => !["active", "sx"].includes(propName as any),
})<DotProps>(({ theme, active }) => ({
  height: "14px",
  width: "14px",
  borderRadius: "50%",
  border: "2px solid hsla(0, 0%, 100%, 0.95)",
  backgroundColor: active
    ? theme.palette.primary.main
    : "hsla(0, 0%, 100%, 0.50)",
  transition: "background-color 0.3s ease",
  "&:hover": {
    scale: "1.2",
  },
  "&:focus-visible": { outline: "2px solid white" },
}));

export type CarrouselImage = {
  id: string;
  imageSrc: string;
  href?: string;
  altText: string;
  title: string;
  blurDataUrl?: string;
};
export type CarrouselProps = {
  images: CarrouselImage[];
  intervalInMilliseconds?: number;
  transitionDurationMs?: number;
  paperProps?: PaperProps;
};

export function Carrousel({
  images,
  intervalInMilliseconds = 4000,
  transitionDurationMs = 1000,
  paperProps,
}: CarrouselProps) {
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:Carrousel" });
  const noImagesMessage = t("noImagesMessage");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  function next() {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  }

  function previous() {
    setCurrentImageIndex(
      (currentImageIndex - 1 + images.length) % images.length
    );
  }

  const { reset, clear } = useInterval(() => {
    next();
  }, intervalInMilliseconds);

  function handleNextClick() {
    next();
    reset();
  }

  function handlePrevClick() {
    previous();
    reset();
  }

  if (images.length === 0) {
    return (
      <Paper
        sx={{
          background: (theme) => theme.palette.grey[400],
          display: "grid",
          placeItems: "center",
          minHeight: "400px",
          ...paperProps?.sx,
        }}
      >
        {noImagesMessage}
      </Paper>
    );
  }
  return (
    <Paper
      onPointerEnter={() => {
        clear();
      }}
      onPointerLeave={() => {
        reset();
      }}
      sx={{
        position: "relative",
        overflow: "hidden",
        background: "black",
        // set child button to display:none
        "& button, & .dots": { opacity: 0, transition: "opacity 0.3s ease" },
        "&:hover button, &:hover .dots, &:focus-visible button, &:focus-visible .dots, &:focus-within button, &:focus-within .dots":
          { opacity: 1 },
        width: "100%",
        height: 300,
        ...paperProps?.sx,
      }}
    >
      {images.map((image, index) => {
        const { href } = image;
        const ImageElement = (
          <Image
            key={image.id}
            src={image.imageSrc}
            alt={image.altText}
            title={image.title}
            fill
            placeholder={image.blurDataUrl ? "blur" : "empty"}
            blurDataURL={image.blurDataUrl}
            style={{
              zIndex: index === currentImageIndex ? 1 : 0,
              objectFit: "cover",
              opacity: index === currentImageIndex ? 1 : 0,
              transition: `opacity ${transitionDurationMs}ms ease`,
            }}
          />
        );
        if (href) {
          return (
            <a
              style={{
                zIndex: index === currentImageIndex ? 1 : 0,
                height: "100%",
                width: "100%",
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: "block",
              }}
              key={image.id}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ImageElement}
            </a>
          );
        }
        return ImageElement;
      })}
      {images.length > 1 && (
        <>
          <StyledButton
            sx={{
              left: 0,
              background:
                "linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)",
            }}
            size="large"
            type="button"
            aria-label="carrousel-back"
            onClick={handlePrevClick}
          >
            <ChevronLeftIcon fontSize="large" />
          </StyledButton>
          <StyledButton
            sx={{
              right: 0,
              background:
                "linear-gradient(270deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)",
            }}
            type="button"
            size="large"
            aria-label="carrousel-forward"
            onClick={handleNextClick}
          >
            <ChevronRightIcon fontSize="large" />
          </StyledButton>
          <Stack
            className="dots"
            position="absolute"
            left={0}
            right={0}
            bottom={4}
            gap={1}
            direction="row"
            zIndex={1}
            justifyContent="center"
            padding={1}
          >
            {images.map((image, index) => (
              <Dot
                key={image.id}
                data-testid="dot"
                active={index === currentImageIndex}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setCurrentImageIndex(index);
                  reset();
                }}
              />
            ))}
          </Stack>
        </>
      )}
    </Paper>
  );
}
