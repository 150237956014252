import { useCallback } from "react";
import { ReqPath } from "core/entities";
import { useAPIFetch } from "./useAPIFetch";

/**
 * @deprecated This function is deprecated and will be removed in future versions.
 * Please use the `useCentrixFetch` instead.
 */
export function useAPIGetJson() {
  const APIFetch = useAPIFetch();
  const apiGetJson = useCallback(
    async (reqPath: ReqPath, options?: RequestInit) => {
      const res = await APIFetch(reqPath, options);
      return res.json();
    },
    [APIFetch]
  );
  return apiGetJson;
}
