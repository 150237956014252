import { useRouter } from "next/router";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_TRANSLATION_KEY,
  DocCategoryDto,
  DocLibraryDto,
  DocTranslationDto,
} from "../entities";

export type ProcessedCategoryLibrary<
  T extends DocLibraryDto | DocCategoryDto = DocLibraryDto | DocCategoryDto,
> = T & {
  currentTranslation: DocTranslationDto | undefined;
  languages: string;
};

/**
 * @returns A callback function that takes either a Category or a Library and
 * determines the current translation based on the current locale. The current locale is
 * added to the dto as currentTranslation. The languages property is a string that lists all the languages
 */
export function useDmsCategoryLibraryProcessor() {
  const { locale } = useRouter();
  const { t } = useTranslation("DocumentManagementSystem");
  const processContainerCallback = useCallback(
    <T extends DocLibraryDto | DocCategoryDto>(
      libraryOrCategory: T
    ): ProcessedCategoryLibrary<T> => {
      const languages =
        libraryOrCategory.translations
          ?.map(({ transKey }) => t(`Next:Core:LanguageCodes.${transKey}`))
          .join(" | ") ?? "";

      let currentLocaleTranslation = libraryOrCategory.translations?.find(
        (translation) => translation?.transKey === locale
      );
      if (!currentLocaleTranslation) {
        currentLocaleTranslation = libraryOrCategory.translations?.find(
          (translation) => translation?.transKey === DEFAULT_TRANSLATION_KEY
        );
      }
      if (!currentLocaleTranslation) {
        currentLocaleTranslation = libraryOrCategory.translations?.[0];
      }

      return {
        ...libraryOrCategory,
        currentTranslation: currentLocaleTranslation,
        languages,
      };
    },
    [t, locale]
  );
  return processContainerCallback;
}
