import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { useToaster } from "core/hooks/useToaster";
import { useTranslation } from "react-i18next";

export function useDownloadDocumentByFileId() {
  const centrixFetch = useCentrixFetch();
  const { errorToast } = useToaster();
  const { t } = useTranslation("Fixhub");
  const downloadErrorMessage = t(
    "Next:Core:useDownloadDocumentByFileId.failure"
  );
  return async (
    fileId: string,
    fileName: string,
    openInNewTab: boolean = false
  ) => {
    const fileDownloadResponse = await centrixFetch({
      method: "get",
      path: "/api/app/doc-document/file/{fileId}",
      parameters: { path: { fileId } },
    });
    if (!fileDownloadResponse.ok) {
      errorToast(downloadErrorMessage);
      return;
    }
    const blob = await fileDownloadResponse.blob().catch(() => null);
    if (!blob) {
      errorToast(downloadErrorMessage);
      return;
    }
    const fileURL = window.URL.createObjectURL(blob);
    const a = document.createElement("a") as HTMLAnchorElement;
    a.href = fileURL;
    a.target = "_blank";
    if (!openInNewTab) {
      a.download = fileName;
    }
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
}
