/**
 * Replaces placeholders in a template string with corresponding values from a variables object.
 * Throws an error if any placeholders are not replaced.
 *
 * @param templateString - The template string with placeholders to be replaced.
 * @param variables - An object containing key-value pairs of placeholders and their corresponding values.
 * @returns The templated string with all placeholders replaced.
 * @throws Error if any placeholders are not replaced.
 *
 * @example
 * ```typescript
 * const templateString = "/api/v1/users/{userId}/profile";
 * const variables = { userId: "123" };
 * const route = templateRoute(templateString, variables);
 * console.log(route); // "/api/v1/users/123/profile"
 * ```
 */
export function templateRoute(
  templateString: string,
  variables: Record<string, string>
) {
  const templatedString = Object.entries(variables).reduce(
    (acc, [key, value]) => acc.replace(`{${key}}`, value),
    templateString
  );
  if (templatedString.includes("{")) {
    const missingParam = templatedString.match(/{(.*?)}/)?.[1];
    throw new Error(`Missing parameter: ${missingParam}`);
  }
  return templatedString;
}
