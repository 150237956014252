import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useMenuItems } from "core/hooks/useMenuItems";
import { MenuItemDto } from "core/entities";
import { NavCategoryLinks } from "./NavCategoryLinks";
import { LibraryLinks } from "./LibraryLinks";

export type NavSectionCategoriesProps = {
  submenus: MenuItemDto[];
  libraries: ReturnType<typeof useMenuItems>["menuItems"][number]["libraries"];
};

export function NavSectionCategories({
  submenus,
  libraries = [],
}: NavSectionCategoriesProps) {
  const areLibraryLinksVisible = libraries.length > 0;
  const categoriesContainingLinks =
    submenus?.filter((category) => category.submenus?.length) || [];

  const numberOfCategoriesToDisplay = areLibraryLinksVisible ? 4 : 5;
  const visibleCategories = categoriesContainingLinks.slice(
    0,
    numberOfCategoriesToDisplay
  );
  const numberOfInitiallyVisibleLinks = 8;

  if (!areLibraryLinksVisible && categoriesContainingLinks.length === 0) {
    return null;
  }

  return (
    <Box component="section" px={2} py={1}>
      <Stack direction="row">
        <Stack direction="row" spacing={4}>
          {libraries.length > 0 && (
            <LibraryLinks
              libraries={libraries}
              numberOfInitiallyVisibleLinks={numberOfInitiallyVisibleLinks}
            />
          )}
          {visibleCategories.map(
            ({
              id: categoryId,
              displayName: categoryDisplayName,
              url: categoryHref,
              target: categoryTarget,
              submenus: categorySubMenus,
            }) =>
              categoryDisplayName ? (
                <NavCategoryLinks
                  id={categoryId!}
                  numberOfInitiallyVisibleLinks={numberOfInitiallyVisibleLinks}
                  key={categoryId}
                  href={categoryHref ?? undefined}
                  target={categoryTarget ?? undefined}
                  submenus={categorySubMenus ?? []}
                  displayName={categoryDisplayName}
                />
              ) : null
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
