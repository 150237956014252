import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import { MenuProps } from "@mui/material/Menu";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { FormControlProps, SxProps, Theme } from "@mui/material";

export interface ReactHookFormSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName;
  label: string;
  loading?: boolean;
  loadingText?: string;
  defaultValue?: string;
  menuProps?: Partial<MenuProps>;
  required?: boolean;
  requiredAsterisk?: boolean;
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  options: { label: string; value: any }[];
  fullWidth?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  onChangeCallback?: (e: SelectChangeEvent<any>) => void;
  control?: Control<TFieldValues>;
  formControlProps?: FormControlProps;
}

export function ReactHookFormSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  loading = false,
  loadingText,
  label,
  menuProps = { PaperProps: { style: { maxHeight: "400px" } } },
  required = false,
  requiredAsterisk = true,
  rules,
  options,
  sx,
  onChangeCallback,
  control,
  formControlProps,
  ...props
}: ReactHookFormSelectProps<TFieldValues, TName>) {
  const labelId = `${name}-label`;
  const { t } = useTranslation("Fixhub");
  const actualLoadingText =
    loadingText || t("Next:Core:ReactHookFormSelect.loading");
  const labelPrefix = required && requiredAsterisk ? "* " : "";
  const actualLabel = `${labelPrefix}${label}`;
  const selectOptions = options;
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required
          ? t("Next:Core:FieldRequiredValidationMessage")
          : false,
        ...rules,
      }}
      render={({
        field: { onChange: reactHookOnChange, ref: inputRef, ...rest },
        fieldState: { error },
      }) => (
        <FormControl {...formControlProps} {...props} error={!!error} sx={sx}>
          <InputLabel id={labelId}>{actualLabel}</InputLabel>
          <Select
            onChange={(e) => {
              reactHookOnChange(e);
              if (typeof onChangeCallback === "function") {
                onChangeCallback(e);
              }
            }}
            {...rest}
            inputRef={inputRef}
            label={actualLabel}
            labelId={labelId}
            MenuProps={menuProps}
            {...props}
            data-testid={`form-select-${name}`}
          >
            {loading && (
              <MenuItem color="text.secondary" disabled>
                {actualLoadingText}
              </MenuItem>
            )}
            {selectOptions.map(({ label: optionLabel, value }) => (
              <MenuItem key={value} value={value}>
                {optionLabel}
              </MenuItem>
            ))}
          </Select>
          {error && error.message && (
            <FormHelperText sx={{ color: "error.main" }}>
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
