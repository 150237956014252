import { useCallback, useRef, useEffect } from "react";
import { getBrandCode } from "core/utils/getBrandCode";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useRouter } from "next/router";
import { BareFetcher, PublicConfiguration } from "swr/_internal";
import { APIError } from "core/entities";
import { useAuth } from "oidc-react";
import { useDebouncedCallback } from "./useDebouncedCallback";
import { useLocalStorage } from "./useLocalStorage";
import { useTrackException } from "./useTrackException";
import { useCentrixFetch } from "./useCentrixFetch";
import { useCentrixApi } from "./useCentrixApi";

type ValueSetter<T> = (
  val: T | ((val: T) => T | T),
  callback?: () => void
) => Promise<void>;

interface Scope {
  brand?: boolean;
  region?: boolean;
}

function useBuildKey(key: string, scope: Scope) {
  const { query } = useRouter();
  const currentBrandCode = getBrandCode();
  if (scope.region && !query.region) {
    throw new Error("Region is not defined in the query string");
  }
  const currentRegion = String(query.region);
  const brandPart = scope.brand ? `${currentBrandCode}-` : "";
  const regionPart = scope.region ? `${currentRegion}-` : "";
  return `${brandPart}${regionPart}${key}`;
}

/**
 * Custom React hook that provides functionality for storing and retrieving user data.
 * @param {string} key - The key under which the data is stored.
 * @param {T} defaultValue - The default value to be returned if no value is found in the cache.
 * @param {Scope} scope - The scope of the key.
 * @param {Partial<PublicConfiguration<{ value: T }, APIError, BareFetcher<{ value: T }>>} options - The options for the SWR hook.
 * @param {(data: any, updateApiValue: (val: T, callback?: () => void) => Promise<void>) => void} onInitialDataLoaded - The callback function that is called when the initial data is loaded. Can be used to migrate data to a new format or to update the value in the cache once on mount.
 * @example
 * ```tsx
 * const {value, setValue} = useUserSetting(
 *   "PREFERRED_PIZZA_TOPPINGS",
 *   {pepperoni: true, mushrooms: false},
 *   {brand: true, region: true},
 *   undefined, // not setting swr options
 *   (data, updateApiValue) => {
 *     if (!("mushroom" in data)){
 *       updateApiValue({...data, mushrooms: data.mushroom}); // updating the value in the api to include the new key/value pair
 *     }
 *   }
 * );
 * ```
 */
export function useUserSetting<
  T extends number | string | Record<any, any> | boolean,
>(
  key: string,
  defaultValue: T,
  scope: Scope = {},
  options?: Partial<
    PublicConfiguration<{ value: T }, APIError, BareFetcher<{ value: T }>>
  >,
  onInitialDataLoaded?: (
    data: any,
    updateApiValue: (val: T, callback?: () => void) => Promise<void>
  ) => void
): {
  value: T;
  setValue: ValueSetter<T>;
  localStorageValue: T;
  databaseValue: T | undefined;
  isLoading: boolean;
  isError: APIError | undefined;
} {
  const trackException = useTrackException();
  const { userData } = useAuth();
  const isUserLoggedIn = Boolean(userData);
  const centrixFetch = useCentrixFetch();
  const fullKey = useBuildKey(key, scope);
  const [localStorageValue, setLocalStorageValue] = useLocalStorage<T>(
    `user-storage.${fullKey}`,
    defaultValue
  );
  const { data, mutate, isError, isLoading } = useCentrixApi({
    path: "/api/app/user-settings",
    parameters: { query: { key: fullKey } },
    shouldFetch: isUserLoggedIn,
    swrOptions: {
      revalidateIfStale: false,
      onError: async (error) => {
        if (error.status !== 404) {
          trackException({
            exception: new Error(`API Error status: ${error.status}`),
            severityLevel: SeverityLevel.Error,
            properties: {
              function: "useUserSetting.useApi.onError",
              key: fullKey,
              localStorageValue,
              apiError: error,
            },
          });
          return;
        }

        await centrixFetch({
          method: "put",
          path: "/api/app/user-settings",
          body: { value: localStorageValue as any, key: fullKey },
        });
      },

      onSuccess: ({ value }) => {
        if (value !== localStorageValue) {
          setLocalStorageValue(value as T);
        }
      },
      ...(options as any),
    },
  });

  /**
   * updated the value in the swr cache. Does not persist to local storage or the api
   */
  const localUpdate = useCallback(
    async (val: T) => {
      await mutate({ value: val as any }, { revalidate: false });
    },
    [mutate]
  );

  const updateApiValue = useCallback(
    async (val: T, callback?: () => void) => {
      const updateResponse = await centrixFetch({
        method: "put",
        path: "/api/app/user-settings",
        body: { value: val as any, key: fullKey },
      });
      if (!updateResponse.ok) {
        return;
      }
      await updateResponse.json();
      await mutate();
      if (callback && typeof callback === "function") {
        callback();
      }
    },
    [centrixFetch, fullKey, mutate]
  );

  const debouncedPersistentUpdate = useDebouncedCallback(
    (...args: Parameters<typeof updateApiValue>) => updateApiValue(...args),
    [updateApiValue],
    500
  );

  const databaseValue = data?.value;
  const value = databaseValue ?? localStorageValue;

  const setValue: ValueSetter<T> = useCallback(
    (valOrFunction, callback) => {
      const newValue =
        typeof valOrFunction === "function"
          ? valOrFunction(value as T)
          : valOrFunction;
      debouncedPersistentUpdate(newValue, callback);
      return localUpdate(newValue);
    },

    [value, debouncedPersistentUpdate, localUpdate]
  );

  const isInitialDataLoaded = useRef(false);

  useEffect(() => {
    if (isLoading || isError || !data) {
      return;
    }
    if (
      typeof onInitialDataLoaded === "function" &&
      !isInitialDataLoaded.current
    ) {
      onInitialDataLoaded(data.value, updateApiValue);
    }

    isInitialDataLoaded.current = true;
  }, [data, isError, isLoading, onInitialDataLoaded, updateApiValue]);

  return {
    value: value as T,
    setValue,
    databaseValue: databaseValue as T,
    localStorageValue,
    isLoading,
    isError,
  };
}
