import type { ThemeOptions } from "@mui/material";

export const NovusGlassThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#0c5ea9",
    },
    secondary: {
      main: "#e7184e",
    },
    background: {
      default: "#E6EBF0",
      paper: "#FAFAFA",
    },
  },
};
