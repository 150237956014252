import { styled } from "@mui/material/styles";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { useNotifications } from "features/Notifications/useNotifications";
import { useMemo } from "react";

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    minHeight: "10px",
    width: "10px",
    borderRadius: "100%",
  },
}));

interface NotificationBadgeProps extends BadgeProps {
  mode: "self" | "parent" | "either";
  id: string;
}
export function NotificationBadge({
  mode,
  id,
  children,
  ...props
}: NotificationBadgeProps) {
  const {
    hasUnreadNotification,
    shouldEntityHaveNotificationDotById,
    hasUnreadNotificationChildren,
  } = useNotifications();
  const shouldShowDot = useMemo(() => {
    switch (mode) {
      case "parent":
        return hasUnreadNotificationChildren(id);
      case "self":
        return hasUnreadNotification(id);
      case "either":
        return shouldEntityHaveNotificationDotById(id);
      default:
        return false;
    }
  }, [
    hasUnreadNotification,
    hasUnreadNotificationChildren,
    id,
    mode,
    shouldEntityHaveNotificationDotById,
  ]);

  return (
    <StyledBadge
      variant="dot"
      color="secondary"
      invisible={!shouldShowDot}
      {...props}
    >
      {children}
    </StyledBadge>
  );
}
