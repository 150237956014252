import {
  DocDocumentCreateUpdateDto,
  NewDocumentFormValues,
} from "../../../../entities";
import { formStateToPermissionSet } from ".";
import { dmsDocumentFormAttachmentsToCreateUpdateDto } from "./dmsDocumentFormAttachmentsToCreateUpdateDto";

export function dmsDocumentFormValuesToCreateUpdateDto(
  {
    availableFrom,
    availableTo,
    isDocumentUpload,
    permissions: formPermissions,
    translations: translationsObject,
    attachments: attachmentsObject,
  }: NewDocumentFormValues,
  parentId: string
): DocDocumentCreateUpdateDto {
  const attachments = dmsDocumentFormAttachmentsToCreateUpdateDto(
    attachmentsObject,
    isDocumentUpload
  );
  const permissions = formStateToPermissionSet(formPermissions);
  const translationEntries = Object.values(translationsObject);
  const translations: DocDocumentCreateUpdateDto["translations"] =
    translationEntries
      .filter(
        (entry): entry is typeof entry & { name: string } =>
          typeof entry.name === "string"
      )
      .map((entry) => ({
        name: entry.name,
        description: entry.description || undefined,
        id: entry.id || undefined,
        isDefault: entry.isDefault || undefined,
        tags: entry.tags || undefined,
        transKey: entry.transKey || "en",
      }));

  const submitData: DocDocumentCreateUpdateDto = {
    availableFrom: availableFrom?.toUTCString() || undefined,
    availableTo: availableTo?.toUTCString() || undefined,
    permissions,
    attachments,
    translations,
    parentId,
  };
  return submitData;
}
