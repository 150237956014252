import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useMainContentFilter } from "core/hooks/useMainContentFilter";
import { useRouter } from "next/router";
import { useMenuItems } from "core/hooks/useMenuItems";
import { DesktopGlobalHeaderNavTab } from "./DesktopGlobalHeaderNavTab";
import { CustomLink } from "../CustomLink";
import { BrandLogo } from "../BrandLogo";

export function DesktopGlobalHeaderNav() {
  const { 1: setIsFilterOn } = useMainContentFilter();
  const { menuItems } = useMenuItems();
  const [tabIndex, setTabIndex] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();
  const [transitionProperty, setTransitionProperty] = useState<
    "transform" | "all"
  >("transform");

  function getHandleChangeTab(index: number, canOpen: boolean) {
    return () => {
      if (!canOpen) {
        return;
      }
      if (tabIndex === index && isMenuOpen) {
        setIsMenuOpen(false);
        setIsFilterOn(false);
        return;
      }
      setTabIndex(index);
      setIsMenuOpen(true);
      setIsFilterOn(true);
    };
  }

  useEffect(() => {
    function closeMenuOnRouteChange() {
      setIsMenuOpen(false);
      setIsFilterOn(false);
    }
    const eventArgs: Parameters<typeof router.events.on> = [
      "routeChangeStart",
      closeMenuOnRouteChange,
    ];
    router.events.on(...eventArgs);
    return () => {
      router.events.off(...eventArgs);
    };
  });

  return (
    <Stack
      component="nav"
      direction="row"
      alignItems="center"
      px={6}
      spacing={3}
      bgcolor="background.paper"
      color="primary.dark"
      maxWidth="100vw"
    >
      <CustomLink sx={{ pt: 1, maxWidth: "200px" }} href="/" passHref>
        <BrandLogo />
      </CustomLink>
      <ClickAwayListener
        onClickAway={() => {
          setIsMenuOpen(false);
          setIsFilterOn(false);
        }}
      >
        <Tabs
          component="nav"
          value={tabIndex}
          aria-label="navigation tabs"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            onTransitionEnd: () => {
              setTransitionProperty(isMenuOpen ? "all" : "transform");
            },
          }}
          sx={{
            flex: 1,
            "& .MuiTabs-indicator": {
              transform: `scale3d(${isMenuOpen ? 1 : 0},1,1)`,
              transformOrigin: "center",
              transitionProperty,
            },
            "& .MuiTabs-flexContainer": {
              gap: 3,
            },
            "& .MuiTab-root": {
              opacity: 1,
            },
          }}
        >
          {menuItems?.map?.((menuItem, index) => {
            const canOpen = Boolean(
              menuItem.libraries.length || menuItem.submenus.length
            );

            return (
              <DesktopGlobalHeaderNavTab
                canOpen={canOpen}
                open={canOpen && isMenuOpen && tabIndex === index}
                key={menuItem.id}
                navTabMenuItemId={menuItem.id!}
                submenus={menuItem.submenus}
                libraries={menuItem.libraries}
                displayName={menuItem.displayName ?? ""}
                target={menuItem.target ?? undefined}
                url={menuItem.url}
                changeTab={getHandleChangeTab(index, canOpen)}
              />
            );
          })}
        </Tabs>
      </ClickAwayListener>
    </Stack>
  );
}
