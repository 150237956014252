import { ALL_VALUE } from "config";
import { components } from "resources/api-schema";

export function extractInvalidPermissions(
  permissionSet: components["schemas"]["Fixhub.FixnDocumentManagementSystem.DocPermissions.DocDocumentPermissionDto"][],
  brandRegionMap: Record<string, string[]>,
  departmentBrandMap: Record<string, string[]>
) {
  return permissionSet.filter(({ brand, region, departmentName, roleId }) => {
    if (!departmentName && !roleId) {
      return false;
    }
    if (brand === ALL_VALUE) {
      return false;
    }
    const brandRegions = (brand && brandRegionMap[brand]) || [];
    const isRegionValid = Boolean(
      region === ALL_VALUE || (region && brandRegions.includes(region))
    );
    // handle role case
    if (roleId) {
      return !isRegionValid;
    }
    const departmentBrands = departmentBrandMap[departmentName!];
    const isDepartmentValid =
      departmentName === ALL_VALUE || departmentBrands?.includes(brand!);
    if (!isDepartmentValid || !isRegionValid) {
      return true;
    }
    return false;
  });
}
