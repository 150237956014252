import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import OpenInNew from "@mui/icons-material/OpenInNew";
import IconButton from "@mui/material/IconButton";
import { CustomLink } from "core/components/CustomLink";
import { MyLinksIcon } from "features/MyLinks/components/MyLinksIcon";
import { SearchResultPage } from "../defs";
import { SearchItemTemplate } from "./SearchItemTemplate";

export function SearchItemPage({
  title,
  blurb,
  isFavorited,
  dynamicData,
}: SearchResultPage) {
  const { url, target } = dynamicData;
  return (
    <SearchItemTemplate
      data-testid="search-item-page"
      secondaryAction={
        <IconButton LinkComponent={CustomLink} target={target} href={url}>
          {target === "_blank" ? (
            <OpenInNew color="primary" />
          ) : (
            <KeyboardDoubleArrowRight color="primary" />
          )}
        </IconButton>
      }
      AvatarIcon={<MyLinksIcon itemType="Page" />}
      title={title}
      blurb={blurb}
      isFavorited={isFavorited}
    />
  );
}
