import { ItemType, FavoriteExtendedDto } from "core/entities";
import { uuid } from "core/utils/uuid";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { ExtractSuccessType } from "core/entities/APITypes";
import { useCallback } from "react";
import { useFavorites } from "./useFavorites";

function addToFavoritesData(
  currentFavoritesData:
    | ExtractSuccessType<"/api/app/favorites/current-user-favorites">
    | undefined,
  entry: FavoriteExtendedDto
) {
  if (!currentFavoritesData || !Array.isArray(currentFavoritesData?.items)) {
    return { items: [entry], totalCount: 1 };
  }
  return {
    items: [...currentFavoritesData.items, entry],
    totalCount: (currentFavoritesData.totalCount ?? 0) + 1,
  };
}

function removeFromFavoritesData(
  dataToFilter:
    | ExtractSuccessType<"/api/app/favorites/current-user-favorites">
    | undefined,
  idToDelete: string
) {
  if (!dataToFilter || !Array.isArray(dataToFilter.items)) {
    return { items: [], totalCount: 0 };
  }
  return {
    items: dataToFilter.items.filter((item) => item.id !== idToDelete),
    totalCount: dataToFilter.totalCount ? dataToFilter.totalCount - 1 : 0,
  };
}

export function useFavorite(
  itemType: ItemType,
  itemId: string,
  title?: string
) {
  const centrixFetch = useCentrixFetch();

  const { data, isError, isLoading, mutate } = useFavorites();

  const favoriteEntry = data?.items?.find(
    (entry) => entry.itemId === itemId && entry.itemType === itemType
  );

  async function addToFavorites() {
    if (favoriteEntry) {
      throw new Error("item already favorited");
    }
    await mutate(
      async (currentData) => {
        const favoriteResponse = await centrixFetch({
          method: "post",
          path: "/api/app/favorites",
          body: { itemType, itemId },
        });
        if (!favoriteResponse.ok) {
          throw new Error("failed to favorite");
        }
        const newFavorite = await favoriteResponse.json();
        return addToFavoritesData(currentData, { ...newFavorite, title });
      },
      {
        optimisticData: (currentCache) =>
          addToFavoritesData(currentCache, {
            id: uuid(),
            title,
            itemId,
            itemType,
          }),
      }
    );
  }

  const removeFromFavorites = useCallback(async () => {
    const idToDelete = favoriteEntry?.id;
    if (!idToDelete) {
      throw new Error("no id found");
    }

    await mutate(
      async (currentData) => {
        const deleteResponse = await centrixFetch({
          method: "delete",
          path: `/api/app/favorites/{id}`,
          parameters: { path: { id: idToDelete } },
        });
        if (!deleteResponse.ok) {
          throw new Error("failed to delete");
        }
        return removeFromFavoritesData(currentData, idToDelete);
      },
      { optimisticData: (cur) => removeFromFavoritesData(cur, idToDelete) }
    );
  }, [centrixFetch, favoriteEntry?.id, mutate]);

  return {
    isLoading,
    isError,
    isFavorite: !!favoriteEntry,
    addToFavorites,
    removeFromFavorites,
  };
}
