import { useTranslation } from "react-i18next";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { TransitionGroup } from "react-transition-group";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { useIsEditMode } from "features/DMS/hooks";
import { DmsDocumentListItem } from "./DmsDocumentListItem";
import { SharedDmsDocumentDisplayProps } from "../DmsDocumentDisplayDefs";

export function DMSDocumentList({
  documents = [],
  docLibraryId,
  showLoadMore = true,
  isLoadingMore = false,
  loadMoreOnClick,
  showPermissions = false,
  addDocumentOnClick,
}: SharedDmsDocumentDisplayProps) {
  const { t } = useTranslation("DocumentManagementSystem");

  const isEditMode = useIsEditMode(docLibraryId);
  return (
    <>
      <List dense disablePadding>
        <TransitionGroup>
          {isEditMode && (
            <Collapse key="addDocument">
              <Button
                data-testid="add-document-button"
                sx={{ mb: 1, p: 2 }}
                fullWidth
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={addDocumentOnClick}
              >
                {t(
                  "Next:DocumentManagementSystem:AddDocumentListItem.addDocument"
                )}
              </Button>
            </Collapse>
          )}
          {documents.map(
            ({
              document: { id, parentId, permissions, publishTime, availableTo },
              currentTranslation,
              currentAttachment,
            }) =>
              id && parentId && currentTranslation?.name && publishTime ? (
                <Collapse key={id}>
                  <DmsDocumentListItem
                    id={id}
                    permissions={permissions ?? []}
                    showPermissions={showPermissions}
                    parentId={parentId}
                    name={currentTranslation?.name}
                    description={currentTranslation?.description}
                    publishTime={publishTime}
                    availableTo={availableTo}
                    fileName={currentAttachment?.fileName}
                    fileId={currentAttachment?.fileId}
                    thumbnailId={currentAttachment?.thumbnailId}
                    thumbnailName={currentAttachment?.thumbnailName}
                    documentLink={currentAttachment?.documentLink}
                    docLibraryId={docLibraryId}
                  />
                </Collapse>
              ) : null
          )}
        </TransitionGroup>
      </List>
      {showLoadMore && (
        <Box display="flex" justifyContent="center">
          <LoadingButton
            variant="outlined"
            startIcon={<ExpandMoreIcon />}
            loading={isLoadingMore}
            onClick={loadMoreOnClick}
          >
            {t("Next:DocumentManagementSystem:DocumentList.showMore")}
          </LoadingButton>
        </Box>
      )}
    </>
  );
}
