import { PageContainer } from "core/components/PageContainer";
import { useTranslation } from "react-i18next";

export function Unauthorized401Page() {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:Unauthorized401Page",
  });
  return (
    <PageContainer>
      <h1>{t("title")}</h1>
    </PageContainer>
  );
}
