import Head from "next/head";
import { useTranslation } from "react-i18next";
import { useBrandName } from "core/hooks/useBrandName";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import { usePageLogging } from "core/hooks/usePageLogging";
import { EventsWidget, NewsWidget } from "features/NewsEvents/components";
import { ComponentErrorBoundary } from "core/components/ComponentErrorBoundary";
import { WeatherWidget } from "features/Weather/components";
import { DMSWidgetSinglePage } from "features/DMS/components";
import { PageContainer } from "core/components/PageContainer";
import { AdvertisementWidget } from "core/components/AdvertisementWidget";

function useResponsiveGridValues(): {
  gridTemplateAreas: string;
  gridTemplateColumns: string;
} {
  const { isDesktop, isTablet } = useBreakpoints();
  const mobileLayout = {
    gridTemplateAreas: `
    "weather"
    "ads"
    "news"
    "events"
    "dms"
  `,
    gridTemplateColumns: "minmax(0,1fr)",
  };
  const tabletLayout = {
    gridTemplateAreas: `
    "weather weather weather weather"
    "ads     ads     ads     ads"
    "news    news    news    news"
    "news    news    news    news"
    "events  events  events   events"
    "events  events  events   events"
    "dms     dms     dms     dms"
    "dms     dms     dms     dms"
  `,
    gridTemplateColumns: "repeat(4,minmax(0,1fr))",
  };
  const desktopLayout = {
    gridTemplateAreas: `
  "weather weather ads ads ads ads ads ads"
  "news news news news news news events events" 
  "news news news news news news events events" 
  "dms dms dms dms dms dms dms dms"
  `,
    gridTemplateColumns: "repeat(8,minmax(0,1fr))",
  };

  if (isDesktop) {
    return desktopLayout;
  }
  if (isTablet) {
    return tabletLayout;
  }
  return mobileLayout;
}

export function LandingPage() {
  const { gridTemplateAreas, gridTemplateColumns } = useResponsiveGridValues();
  usePageLogging({
    itemType: "Page",
    itemId: "home",
    componentName: "LandingPage",
  });
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:RegionLandingPage",
  });
  const brandName = useBrandName();

  return (
    <>
      <Head>
        <title>{t("headTitle", { brandName })}</title>
        <meta name="description" content="Pitstop landing page" />
      </Head>

      <PageContainer
        data-testid="landing-page"
        component="main"
        sx={{
          display: "grid",
          columnGap: 5,
          rowGap: 2,
          gridAutoRows: "min(175px,auto)",
          gridTemplateAreas,
          gridTemplateColumns,
        }}
        maxWidth="xl"
      >
        <ComponentErrorBoundary
          fallBackBoxProps={{ sx: { gridArea: "weather" } }}
        >
          <WeatherWidget />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary fallBackBoxProps={{ sx: { gridArea: "news" } }}>
          <NewsWidget />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary
          fallBackBoxProps={{ sx: { gridArea: "events" } }}
        >
          <EventsWidget />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary fallBackBoxProps={{ sx: { gridArea: "ads" } }}>
          <AdvertisementWidget />
        </ComponentErrorBoundary>
        <ComponentErrorBoundary fallBackBoxProps={{ sx: { gridArea: "dms" } }}>
          <DMSWidgetSinglePage />
        </ComponentErrorBoundary>
      </PageContainer>
    </>
  );
}
