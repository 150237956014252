export function chunkify<T>(array: T[], chunkSize: number = 10): T[][] {
  const chunks = Array.from(
    { length: Math.ceil(array.length / chunkSize) },
    (_, i) => {
      const start = chunkSize * i;
      return array.slice(start, start + chunkSize);
    }
  );
  return chunks;
}
