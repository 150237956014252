import { validateResponse } from "core/utils/validateResponse";

export async function jsonFetch<T>(
  path: string | URL | Request,
  options?: RequestInit
): Promise<T> {
  const res = await fetch(path, {
    ...options,
    headers: {
      Accept: "application/json",
      ...options?.headers,
    },
  });
  await validateResponse(res);
  try {
    const data = await res.json();
    return data as T;
  } catch (error: any) {
    error.info = { message: "Error parsing JSON" };
    error.status = res.status;
    throw error;
  }
}
