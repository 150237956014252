import { DocDocumentDto } from "features/DMS/entities";
import { ViewablePermissionsFormValues } from "../defs";

export function dmsDocumentPermissionsToViewablePermissionsFormState(
  fieldValue: DocDocumentDto["permissions"]
): ViewablePermissionsFormValues {
  const brands = new Set<string>();
  const regions = new Set<string>();
  const corporateRoles = new Set<string>();
  const departments = new Set<string>();
  const shops = new Set<string>();
  const shopRoles = new Set<string>();
  let toggle: ViewablePermissionsFormValues["toggle"] = "departments";
  fieldValue?.forEach(
    ({ brand, region, departmentName, shopId, roleId, shopRoleId }) => {
      if ((departmentName || roleId) && brand && region) {
        brands.add(brand);
        regions.add(region);
      }
      if (departmentName) {
        departments.add(departmentName);
      }
      if (roleId) {
        toggle = "roles";
        corporateRoles.add(roleId);
      }
      if (shopId) {
        shops.add(shopId);
      }
      if (shopRoleId) {
        shopRoles.add(shopRoleId);
      }
    }
  );
  return {
    isVisibleToShops: shops.size > 0 && shopRoles.size > 0,
    brands: [...brands],
    regions: [...regions],
    departments: [...departments],
    corporateRoles: [...corporateRoles],
    shops: [...shops],
    shopRoles: [...shopRoles],
    toggle,
  };
}
