import { useState, MouseEvent, SyntheticEvent } from "react";
import { useFormContext } from "react-hook-form";
import { TranslationKey, TranslationKeys } from "features/DMS/entities";
import { useAvailableLocales } from "core/hooks/useAvailableLocales";
import { DMSLanguageTabsProps } from "./DMSLanguageTabs";

export function useTranslationFields(mode: "container" | "document"): {
  currentLanguages: TranslationKey[];
  selectedLanguage: TranslationKey;
  previousLanguage: TranslationKey;
  languageTabsProps: DMSLanguageTabsProps;
} {
  const [selectedLanguage, setSelectedLanguage] =
    useState<TranslationKey>("en");
  const [previousLanguage, setPreviousLanguage] =
    useState<TranslationKey>("en");

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const { unregister, watch, setValue, trigger, reset, getValues } =
    useFormContext();

  const { availableLocales } = useAvailableLocales();
  // TODO refactor to prevent unnecessary rerenders. Maybe move 'languages' into it's own form property;
  const translations = watch("translations") || {};
  const languages = Object.keys(translations) as TranslationKey[];
  const currentLanguages = TranslationKeys.filter((lg) =>
    languages.includes(lg)
  );

  if (!languages.includes(selectedLanguage)) {
    setSelectedLanguage(languages[0]!);
  }

  function handleTabChange(e: SyntheticEvent, newTabValue: TranslationKey) {
    if (TranslationKeys.includes(newTabValue)) {
      setPreviousLanguage(selectedLanguage);
      setSelectedLanguage(newTabValue);
    }
  }

  function newLanguageOnClick(event: MouseEvent<HTMLDivElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function handleLanguageSelectMenuClose(newSelectedLanguage: TranslationKey) {
    if (TranslationKeys.includes(newSelectedLanguage)) {
      setValue("translations", {
        ...translations,
        [newSelectedLanguage]: {
          transKey: newSelectedLanguage,
          name: "",
          description: "",
          tags: [],
        },
      });
      setPreviousLanguage(selectedLanguage);
      setSelectedLanguage(newSelectedLanguage);
      setMenuAnchor(null);
    }
    setMenuAnchor(null);
  }

  async function removeLanguageOnClick() {
    setSelectedLanguage((currentTabValue) => {
      unregister(`attachments.${currentTabValue}`);
      unregister(`translations.${currentTabValue}`);
      const newLg = languages.find((lg) => lg !== currentTabValue) ?? "en";
      return newLg;
    });
    // force form to revalidate translations to avoid wrong isValid state
    await trigger("translations");
    reset(getValues());
  }

  const unselectedLanguageOptions = (
    availableLocales as TranslationKey[]
  ).filter((lg) => !languages.includes(lg));

  return {
    currentLanguages,
    selectedLanguage,
    previousLanguage,
    languageTabsProps: {
      mode,
      handleTabChange,
      tabValue: selectedLanguage,
      currentLanguages,
      unselectedLanguageOptions,
      newLanguageOnClick,
      removeLanguageOnClick,
      menuAnchor,
      handleLanguageSelectMenuClose,
    },
  };
}
