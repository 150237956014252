import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useToaster } from "core/hooks/useToaster";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { arrayToObject } from "core/utils/arrayToObject";
import { useCentrixApi } from "core/hooks/useCentrixApi";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { DmsCategoryForm, defaultValues } from "./DmsCategoryForm";
import { useCategories, useDMSFormErrorHandler } from "../hooks";
import { ManagePaper } from "./ManagePaper";
import { CategoryFormDetailsSkeleton } from "./CategoryFormDetailsSkeleton";
import { CategoryFormValues } from "../entities";

interface CategoryEditProps {
  cancelOnClick: () => void;
  docLibraryId: string;
  id: string;
  canManageLibraryContents: boolean;
}

export function CategoryEdit({
  cancelOnClick,
  docLibraryId,
  id,
  canManageLibraryContents,
}: CategoryEditProps) {
  const formMethods = useForm<CategoryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const {
    formState: { isSubmitting },
  } = formMethods;
  const { t } = useTranslation("DocumentManagementSystem");
  const centrixFetch = useCentrixFetch();
  const { successToast, errorToast } = useToaster();
  const { mutate: mutateParent } = useCategories({
    mode: "manage",
    parentId: docLibraryId,
    canManageLibraryContents,
  });

  const { errorHandler } = useDMSFormErrorHandler(formMethods, "CategoryForm");
  const { isLoading, isError, data, mutate } = useCentrixApi({
    path: "/api/app/doc-category/{id}",
    parameters: { path: { id } },
  });

  useEffect(() => {
    if (!isLoading && !isError && data) {
      const { translations: translationsArray, ...rest } =
        structuredClone(data);
      const translations = arrayToObject(
        translationsArray!.map((obj) => ({ ...obj })),
        "transKey"
      );
      formMethods.reset({ translations, ...rest });
    }
  }, [data, isLoading, isError, formMethods]);

  const { handleSubmit } = formMethods;

  async function onValid({
    translations: translationsObject,
  }: CategoryFormValues) {
    const translations = Object.values(translationsObject).filter(
      (entry): entry is typeof entry & { name: string } =>
        typeof entry.name === "string"
    );
    const categoryEditResponse = await centrixFetch({
      method: "put",
      path: "/api/app/doc-category/{id}",
      parameters: { path: { id } },
      body: { docLibraryId, translations },
    });
    if (!categoryEditResponse.ok) {
      errorToast(t("Next:DocumentManagementSystem:CategoryEdit.PUTError"));
      return;
    }
    const updatedCategory = await categoryEditResponse.json();
    await Promise.all([mutate(updatedCategory), mutateParent()]);
    successToast(t("Next:DocumentManagementSystem:CategoryEdit.PUTSuccess"));
    cancelOnClick();
  }

  const onSubmit = handleSubmit(onValid, errorHandler);
  return (
    <>
      <Typography variant="h3" textAlign="center" color="primary.main" my={3}>
        {t("Next:DocumentManagementSystem:CategoryEdit.title")}
      </Typography>
      {isLoading ? (
        <ManagePaper
          sectionTitle={t(
            "Next:DocumentManagementSystem:CategoryForm.detailsTitle"
          )}
        >
          <CategoryFormDetailsSkeleton />
        </ManagePaper>
      ) : (
        <DmsCategoryForm
          cancelOnClick={cancelOnClick}
          onSubmit={onSubmit}
          formMethods={formMethods}
          formType="CategoryForm"
          mode="edit"
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
}
