import {
  DMSWidgetFileListItemData,
  DMSWidgetLinkListItemData,
  DMSWidgetListItemData,
  DMSWidgetListItemParent,
  DocCategoryDto,
  DocDocumentDto,
  DocLibraryDto,
  TranslationKey,
} from "features/DMS/entities";
import {
  extractAttachment,
  extractCurrentTranslation,
  extractDefaultTranslationKey,
} from "features/DMS/utils";

export function formatDocumentDataForWidget(
  data: DocDocumentDto,
  locale: TranslationKey,
  menuItemIdDisplayNameMap: Record<string, string>
): DMSWidgetListItemData | undefined {
  // handle link case
  const translation = extractCurrentTranslation(
    data.translations ?? [],
    locale
  );
  const defaultTranslationKey = extractDefaultTranslationKey(
    data.translations ?? []
  );
  const attachment = extractAttachment(
    data.attachments ?? [],
    locale,
    defaultTranslationKey
  );
  const parent = data.parents?.[0] as DocLibraryDto;
  const menuItemId = parent?.menuItemId;
  const menuItemDisplayName =
    menuItemIdDisplayNameMap[parent?.menuItemId as string];
  const menuItemParent = menuItemId &&
    menuItemDisplayName && { id: menuItemId, name: menuItemDisplayName };
  const parents: DMSWidgetListItemParent[] = (
    data.parents as [DocLibraryDto, DocCategoryDto, DocCategoryDto]
  )
    ?.map(({ id, translations }, index) => {
      const isLibrary = index === 0;
      const { name } =
        extractCurrentTranslation(translations ?? [], locale) ?? {};
      const href = `/dms/library/${id}`;
      return isLibrary ? { id, name, href } : { id, name };
    })
    .filter((entry): entry is DMSWidgetListItemParent =>
      Boolean(entry.id && entry.name)
    );

  if (menuItemParent) {
    parents.unshift(menuItemParent);
  }

  if (attachment?.documentLink && translation?.name) {
    const linkItemData: DMSWidgetLinkListItemData = {
      id: data.id!,
      name: translation?.name,
      type: "link",
      documentLink: attachment.documentLink,
      publishTime: data.publishTime,
      lastModificationTime: data.lastModificationTime,
      parents,
      tags: translation?.tags ?? [],
    };
    return linkItemData;
  }
  if (attachment?.fileId && attachment?.fileName && translation?.name) {
    const fileItemData: DMSWidgetFileListItemData = {
      type: "file",
      name: translation.name,
      fileId: attachment.fileId,
      fileName: attachment.fileName,
      id: data.id!,
      publishTime: data.publishTime,
      lastModificationTime: data.lastModificationTime,
      parents,
      tags: translation.tags ?? [],
    };
    return fileItemData;
  }
  return undefined;
}
