import { copyTranslationsToClipboard } from "i18n.config";
import React from "react";
import { isDevelopment } from "config";
import type { NextComponentType, NextPageContext } from "next";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { PublicLanguageSelect } from "./PublicLanguageSelect";

export function PublicApp({
  Component,
  pageProps,
}: {
  Component: NextComponentType<NextPageContext, any, any>;
  pageProps: any;
}) {
  return (
    <>
      <Stack p={1} direction="row" gap={2} justifyContent="flex-end">
        {isDevelopment && (
          <Button
            variant="contained"
            color="primary"
            onClick={copyTranslationsToClipboard}
          >
            Get Translations
          </Button>
        )}
        <PublicLanguageSelect />
      </Stack>
      <Component {...pageProps} />
    </>
  );
}
