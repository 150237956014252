import { useRouter } from "next/router";
import { useCentrixApi } from "core/hooks/useCentrixApi";

export interface UseViewableDocumentsOptions {
  skipCount?: number;
  maxResultCount?: number;
  sorting?: "publishTime desc" | "lastModificationTime desc";
  isDocumentModified?: boolean | undefined;
}

export function useViewableDocuments(options?: UseViewableDocumentsOptions) {
  const { locale } = useRouter();
  const {
    skipCount = 0,
    maxResultCount = 5,
    sorting = "publishTime desc",
    isDocumentModified,
  } = options || {};
  return useCentrixApi({
    path: "/api/app/doc-document/any-viewable-list",
    parameters: {
      query: {
        Locale: locale!,
        SkipCount: skipCount,
        MaxResultCount: maxResultCount,
        Sorting: sorting,
        IsDocumentModified: isDocumentModified,
      },
    },
  });
}
