import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

export function ReactHookTagInput<
  T extends FieldValues = FieldValues,
  C = any,
>({
  name,
  label,
  fullWidth = false,
  control,
}: {
  name: FieldPath<T>;
  control?: Control<T, C>;
  label: string;
  fullWidth?: boolean;
}) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:Autocomplete",
  });
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref: inputRef, ...field } }) => (
        <Autocomplete
          noOptionsText={t("noOptions")}
          freeSolo
          multiple
          id="tags"
          options={[]}
          clearOnBlur
          {...field}
          onChange={(_, tags) => {
            field.onChange([...new Set(tags)]);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={inputRef}
              label={label}
              fullWidth={fullWidth}
              onBlur={(e) => {
                const newTag = e.target.value.trim();
                if (newTag) {
                  field.onChange([...new Set([...field.value, newTag])]);
                }
              }}
            />
          )}
        />
      )}
    />
  );
}
