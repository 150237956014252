import { useCallback } from "react";
import { useAPIHeaders } from "./useAPIHeaders";
import { jsonFetch } from "../utils/jsonFetch";

export function useJsonFetcherWithHeaders() {
  const headers = useAPIHeaders();
  const apiFetch = useCallback(
    <T>(path: string | URL | Request, options?: RequestInit): Promise<T> =>
      jsonFetch<T>(path, {
        ...options,
        headers: {
          ...headers,
          ...options?.headers,
        },
      }),
    [headers]
  );
  return apiFetch;
}
