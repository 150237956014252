import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useIntlFormatterLocales } from "core/hooks/useIntlFormatterLocales";

export interface PermsDisplayItemProps {
  label: string;
  tags: string[];
  displayMax?: number;
}

export function PermDisplayItem({
  label,
  tags,
  displayMax = 2,
}: PermsDisplayItemProps) {
  const locales = useIntlFormatterLocales();
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:PermDisplay",
  });
  const formatter = new Intl.ListFormat(locales, {
    type: "conjunction",
  });
  const [visibleTags, hiddenTags] = [
    tags.slice(0, displayMax),
    tags.slice(displayMax),
  ];
  const tagsToDisplay = hiddenTags.length
    ? [...visibleTags, t("moreCount", { count: hiddenTags.length })]
    : visibleTags;
  const permList = formatter.format(tagsToDisplay);
  const text = tags.length ? permList : t("none");

  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary">
        {label}:
      </Typography>
      <Tooltip
        title={
          hiddenTags.length > 0 ? (
            <ul style={{ paddingInlineStart: 16 }}>
              {tags.map((tag) => (
                <li key={tag}>{tag}</li>
              ))}
            </ul>
          ) : (
            ""
          )
        }
        placement="bottom-start"
      >
        <Typography>{text}</Typography>
      </Tooltip>
    </Box>
  );
}
