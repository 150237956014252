import { useCallback } from "react";
import { ReqPath } from "core/entities";
import { APIFetch } from "core/utils/APIFetch";
import { buildQueryString } from "core/utils/buildQueryString";
import { validateResponse } from "core/utils/validateResponse";
import { useAPIHeaders } from "./useAPIHeaders";

/**
 * @deprecated This function is deprecated and will be removed in future versions.
 * Please use the `useCentrixFetch` instead.
 */
export function useAPIFetch() {
  const headers = useAPIHeaders();
  const apiFetch = useCallback(
    async (reqPath: ReqPath, options?: RequestInit) => {
      let res: Response;
      if (typeof reqPath === "string") {
        res = await APIFetch(reqPath, {
          ...options,
          headers: { ...headers, ...options?.headers },
        });
      } else {
        const { path, params } = reqPath;
        const queryString = buildQueryString(params);
        const fullPath = queryString ? `${path}?${queryString}` : path;
        res = await APIFetch(fullPath, {
          ...options,
          headers: { ...headers, ...options?.headers },
        });
      }
      await validateResponse(res);
      return res;
    },
    [headers]
  );
  return apiFetch;
}
