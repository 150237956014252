import { DocDocumentAttachmentDto, TranslationKey } from "../entities";

export function extractAttachment(
  attachments: DocDocumentAttachmentDto[],
  currentLocale: TranslationKey,
  defaultTransKey?: TranslationKey
): DocDocumentAttachmentDto | undefined {
  // if an attachment is available in the current locale, return it.
  const localeAttachment = attachments.find(
    ({ transKey, fileId, documentLink }) =>
      transKey === currentLocale && (fileId || documentLink)
  );
  if (localeAttachment) {
    return localeAttachment;
  }

  // if no localeAttachment is available in the current locale return the default
  return attachments.find(
    ({ transKey, fileId, documentLink }) =>
      transKey === defaultTransKey && (fileId || documentLink)
  );
}
