import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { CustomLink } from "core/components/CustomLink";
import { useFormatDate } from "core/hooks/useFormatDate";
import { ICategorySummaryDto } from "features/NewsEvents/entities";
import Image from "next/legacy/image";
import { getImageSRC } from "features/NewsEvents/utils";
import { useTranslation } from "react-i18next";

export interface NewsWidgetTileProps {
  id: string;
  title: string;
  blurb: string;
  publishedTime: Date | string;
  bannerBlobName: string;
  bannerAltText: string;
  reverse: boolean;
  blurDataUrl: string;
  articleType: ICategorySummaryDto;
  isImportant?: boolean;
}
export function NewsWidgetTile({
  id,
  title,
  blurb,
  publishedTime,
  bannerBlobName,
  bannerAltText,
  blurDataUrl,
  reverse,
  articleType,
  isImportant,
}: NewsWidgetTileProps) {
  const { t } = useTranslation("NewsEvents", {
    keyPrefix: "Next:NewsEvents:NewsWidgetTile",
  });
  const formattedDate = useFormatDate()(publishedTime);
  const imageSRC = getImageSRC(bannerBlobName);
  return (
    <Stack
      component="article"
      minHeight="250px"
      direction={reverse ? "row-reverse" : "row"}
      flexWrap="wrap"
      justifyContent="space-evenly"
      gap={4}
      sx={{
        paddingBlock: 4,
        "&:not(:last-of-type)": {
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        },
        "& .tileImage": {
          borderRadius: (theme) => theme.shape.borderRadius,
        },
      }}
    >
      <Box
        position="relative"
        sx={{ aspectRatio: "auto 5 / 4" }}
        width="min(300px, 100%)"
        display="flex"
        flexDirection="column"
      >
        <CustomLink href={`/news/${id}`} sx={{ flex: 1, position: "relative" }}>
          <Image
            src={imageSRC}
            alt={bannerAltText}
            layout="fill"
            objectFit="cover"
            className="tileImage"
            objectPosition="50% 50%"
            placeholder="blur"
            blurDataURL={blurDataUrl}
          />
        </CustomLink>
        {isImportant && (
          <PriorityHighRoundedIcon
            sx={{
              zIndex: 1,
              position: "absolute",
              top: ({ spacing }) => spacing(1),
              left: ({ spacing }) => spacing(1),
              color: "error.main",
            }}
          />
        )}
        {articleType && (
          <CustomLink href={`/news?types=${articleType.id}`}>
            <Chip
              clickable
              color="primary"
              label={articleType.title}
              sx={{
                zIndex: 1,
                position: "absolute",
                top: ({ spacing }) => spacing(1),
                right: ({ spacing }) => spacing(1),
              }}
            />
          </CustomLink>
        )}
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          minWidth: "min(225px,100%)",
          maxWidth: "66ch",
        }}
      >
        <Stack justifyContent="space-between">
          <Box>
            <CustomLink href={`/news/${id}`} passHref>
              <Typography variant="h5">{title}</Typography>
            </CustomLink>
            <Typography variant="body2" color="text.secondary" mb={2}>
              {formattedDate}
            </Typography>
            <Typography
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 5,
              }}
              mb={2}
              variant="body2"
            >
              {blurb}
            </Typography>
          </Box>
          <Stack
            direction="row"
            justifyContent={reverse ? "flex-start" : "flex-end"}
          >
            <CustomLink href={`/news/${id}`} passHref>
              <Button>{t("readMore")}</Button>
            </CustomLink>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
