import Avatar from "@mui/material/Avatar";
import { MouseEventHandler } from "react";
import { useDmsDocumentHref } from "features/DMS/hooks";
import { SvgFileIcon } from "core/components/SvgFileIcon";
import { DocDocumentDto } from "features/DMS/entities/DmsDefs";
import { DmsDocumentListItemTemplate } from "./DmsDocumentListItemTemplate";
import { ImageAvatar } from "./ImageAvatar";

export interface DmsDocumentListItemFileProps {
  id: string;
  isEditMode: boolean;
  log: () => Promise<void>;
  onEditClick: MouseEventHandler<HTMLButtonElement>;
  onDeleteClick: MouseEventHandler<HTMLButtonElement>;
  addedDate: string;
  name: string;
  description?: string | null | undefined;
  expiryDate?: string | null | undefined;
  showPermissions: boolean;
  permissions: DocDocumentDto["permissions"];
  docLibraryId: string;
  fileName: string;
  fileId: string;
  thumbnailId?: string | null | undefined;
  thumbnailName?: string | null | undefined;
}
export function DmsDocumentListItemFile({
  fileName,
  fileId,
  thumbnailId,
  thumbnailName,
  log,
  ...rest
}: DmsDocumentListItemFileProps) {
  const href = useDmsDocumentHref(fileId, fileName);
  function onClick() {
    log();
  }
  return (
    <DmsDocumentListItemTemplate
      listItemButtonProps={{
        href,
        target: "_blank",
        onClick,
      }}
      AvatarSlot={
        thumbnailId && thumbnailName ? (
          <ImageAvatar
            thumbnailId={thumbnailId}
            thumbnailName={thumbnailName}
          />
        ) : (
          <Avatar
            sx={{
              bgcolor: "divider",
              color: (theme) => theme.palette.text.secondary,
              "&:hover": { cursor: "pointer" },
            }}
          >
            <SvgFileIcon fileName={fileName} />
          </Avatar>
        )
      }
      {...rest}
    />
  );
}
