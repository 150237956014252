import { FormEventHandler } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ContainerTranslationsFields } from "./TranslationsFields";
import { ManagePaper } from "./ManagePaper";
import { DMSFormButtons } from "./DMSFormButtons";
import { CategoryFormValues } from "../entities";

export interface CategoryFormProps {
  formMethods: UseFormReturn<CategoryFormValues>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  cancelOnClick: () => void;
  clearOnClick?: () => void;
  formType: "CategoryForm" | "SubCategoryForm";
  mode: "add" | "edit";
  isSubmitting: boolean;
}

/**
 *
 * @param {Object} formMethods - the object returned form the "react-hook-form" useForm() hook
 * @param {Function} cancelOnClick - function to be called to close the parent modal
 * @param {string} formType - used in translation keys
 */
export function DmsCategoryForm({
  formMethods,
  onSubmit,
  cancelOnClick,
  clearOnClick,
  mode,
  formType,
  isSubmitting,
}: CategoryFormProps) {
  const { t } = useTranslation("DocumentManagementSystem");
  return (
    <FormProvider {...formMethods}>
      <Box component="form" onSubmit={onSubmit}>
        <ManagePaper
          sectionTitle={t(
            `Next:DocumentManagementSystem:${formType}.detailsTitle`
          )}
          sx={{ overflow: "hidden" }}
        >
          <Stack spacing={2}>
            <ContainerTranslationsFields formType={formType} />
          </Stack>
        </ManagePaper>
        <DMSFormButtons
          cancelOnClick={cancelOnClick}
          formType={formType}
          mode={mode}
          clearOnClick={clearOnClick}
          isSubmitting={isSubmitting}
        />
      </Box>
    </FormProvider>
  );
}

export const defaultValues: CategoryFormValues = {
  translations: {
    en: {
      transKey: "en",
      name: "",
      description: "",
      tags: [],
    },
  },
};
