import GlobalStyles, { GlobalStylesProps } from "@mui/material/GlobalStyles";

const styles: GlobalStylesProps["styles"] = {
  a: {
    textDecoration: "none",
  },
};

export function GlobalStylesComponent() {
  return <GlobalStyles styles={styles} />;
}
