import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import Stack from "@mui/material/Stack";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";

export interface DMSWidgetListItemButtonsProps {
  isMobile: boolean;
  isPDF: boolean;
  onLinkClick: () => void;
  download: () => void;
  visitHref: string;
}

export function DMSWidgetDocumentListItemButtons({
  isMobile,
  isPDF,
  onLinkClick,
  download,
  visitHref,
}: DMSWidgetListItemButtonsProps) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:DMSWidgetDocumentListItem",
  });
  if (!isMobile && !isPDF) {
    return (
      <Button endIcon={<DownloadIcon />} onClick={download}>
        {t("downloadButton")}
      </Button>
    );
  }
  if (!isMobile && isPDF) {
    return (
      <Stack>
        <Button endIcon={<DownloadIcon />} onClick={download}>
          {t("downloadButton")}
        </Button>
        <Button
          onClick={onLinkClick}
          target="_blank"
          href={visitHref}
          endIcon={<ChevronRight />}
        >
          {t("visitButton")}
        </Button>
      </Stack>
    );
  }
  // else mobile
  if (isPDF) {
    return (
      <Stack sx={{ "& > *": { flex: 1 } }}>
        <Button onClick={download}>
          <DownloadIcon />
        </Button>
        <Button onClick={onLinkClick} href={visitHref} target="_blank">
          <ChevronRight />
        </Button>
      </Stack>
    );
  }
  return (
    <Button onClick={download}>
      <DownloadIcon />
    </Button>
  );
}
