import type { ThemeOptions } from "@mui/material";

export const SpeedyGlassThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#ee3124",
    },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#E6EBF0",
      paper: "#FAFAFA",
    },
  },
};
