import { RegionCode } from "core/entities";
import { regionCodes } from "brandRegionConfig";

export function isValidRegion(
  input: unknown,
  validRegionCodes: readonly RegionCode[] = regionCodes
): input is RegionCode {
  if (typeof input !== "string") {
    return false;
  }
  return validRegionCodes.includes(input as RegionCode);
}
