import { ItemType } from "core/entities";
import { isValidURL } from "core/utils/isValidURL";
import { exhaustiveCheck } from "core/utils/exhaustiveCheck";
import { PageId, pageItemIdPathMap } from "core/configs/pageIdPathMap";

export function getMyLinksHref<T extends ItemType>(
  itemType: T,
  itemId: T extends "Page" ? PageId : string
): string {
  switch (itemType) {
    case "Extenso":
      return `/extenso/${itemId}`;
    case "Event":
      return `/events/${itemId}`;
    case "News":
      return `/news/${itemId}`;
    case "DmsLibrary":
      return `/dms/library/${itemId}`;
    case "DmsDocument":
      return `/dms/document/${itemId}`;
    case "External":
      return isValidURL(itemId) ? itemId : `https://${itemId}`;
    case "Page":
      return pageItemIdPathMap[itemId as PageId] ?? "/";
    case "CMSKitPage":
      return `/${itemId}`;
    case "CMSKitBlogPost":
      return `/content/${itemId}`;
    case "Report":
      return "/404"; // not implemented yet
    case "EstimateRequest":
      return `/estimate-request/${itemId}`; // not implemented yet
    default:
      exhaustiveCheck(itemType);
      return "/";
  }
}
