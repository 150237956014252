import { useDebounce } from "core/hooks/useDebounce";
import { useState } from "react";

/**
 * A custom hook for debouncing a variable.
 * @param value - The value to debounce.
 * @param delay - The delay in milliseconds.
 * @returns The debounced value.
 */
export function useVariableDebounced<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useDebounce(() => setDebouncedValue(value), [value], delay);
  return debouncedValue;
}
