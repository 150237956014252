import { useMemo } from "react";
import { useCentrixApi } from "core/hooks/useCentrixApi";
import { useDmsCategoryLibraryProcessor } from "./useDmsCategoryLibraryProcessor";

export function useLibraryById(id: string, shouldFetch = true) {
  const { data, isError, isLoading, mutate } = useCentrixApi({
    path: "/api/app/doc-library/{id}",
    parameters: { path: { id } },
    shouldFetch,
  });
  const processedLibrary = useDmsCategoryLibraryProcessor();
  const libraryWithTranslation = useMemo(() => {
    if (isLoading || isError || !data) {
      return undefined;
    }
    const processedData = processedLibrary(data);

    return processedData;
  }, [isLoading, isError, data, processedLibrary]);

  return {
    data,
    libraryWithTranslation,
    isError,
    isLoading,
    mutate,
  };
}
