import { useToaster } from "core/hooks/useToaster";
import { ComponentSwitcher } from "core/components/ComponentSwitcher";
import { ReactHookFileInput } from "core/components/ReactHookFormComponents/ReactHookFileInput";
import { ReactHookTagInput } from "core/components/ReactHookFormComponents/ReactHookTagInput";
import { ReactHookTextField } from "core/components/ReactHookFormComponents/ReactHookTextField";
import { ReactHookCheckbox } from "core/components/ReactHookFormComponents/ReactHookCheckbox";
import { SwitchySwitch2 } from "core/components/SwitchySwitch2";
import { useState, useLayoutEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { DocumentFormValues } from "features/DMS/entities";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { useTranslationFields } from "./useTranslationFields";
import { DMSLanguageTabs } from "./DMSLanguageTabs";
import { determineSlideDirection } from "./determineSlideDirection";

export function DocumentTranslationsFields() {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:DocumentTranslationsFields",
  });
  const { register, setValue, watch } = useFormContext();
  const {
    currentLanguages,
    selectedLanguage,
    previousLanguage,
    languageTabsProps,
  } = useTranslationFields("document");
  const [langInputHeight, setLangInputHeight] = useState(0);
  const [isDocumentUpload, translations]: [
    isDocumentUpload: boolean,
    translations: DocumentFormValues["translations"],
  ] = watch(["isDocumentUpload", "translations"]);
  const { errorToast } = useToaster();
  const defaultLanguage =
    translations &&
    Object.values(translations).find(({ isDefault }) => isDefault)?.transKey;

  const centrixFetch = useCentrixFetch();
  const containers = useRef<HTMLElement[]>([]);
  const observer = useRef(
    new ResizeObserver((entries) => {
      const boxHeight = entries?.reduce((max, current) => {
        const currentHeight = current?.contentRect?.height || 0;
        return currentHeight > max ? currentHeight : max;
      }, 0);
      setLangInputHeight(boxHeight);
    })
  );

  useLayoutEffect(() => {
    const resizeObserver = observer.current;
    containers.current?.forEach((el) => {
      if (!el) {
        return;
      }
      resizeObserver?.observe(el);
    });
    return () => {
      resizeObserver?.disconnect();
    };
  }, [observer, currentLanguages]);
  const thumbnailUploadErrorMessage = t("thumbnailUploadFailure");
  const invalidThumbnailFileType = t("invalidThumbnailFileType");
  const fileInputUploadErrorMessage = t("fileInputUploadFailureToastMessage");

  return (
    <Stack spacing={2}>
      <DMSLanguageTabs {...languageTabsProps} />
      <Box position="relative" sx={{ height: langInputHeight }}>
        {currentLanguages.map((transKey, index) => (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: transKey === selectedLanguage ? 1 : 0,
            }}
            key={`${transKey}`}
          >
            <input
              type="hidden"
              {...register(`translations.${transKey}.transKey`, {
                value: transKey,
              })}
            />
            <input
              type="hidden"
              {...register(`attachments.${transKey}.transKey`, {
                value: transKey,
              })}
            />

            <Slide
              appear={index !== 0}
              key={transKey}
              in={selectedLanguage === transKey}
              direction={determineSlideDirection(
                index,
                currentLanguages,
                previousLanguage,
                selectedLanguage
              )}
            >
              <Stack
                spacing={2}
                ref={(el: HTMLElement | null) => {
                  if (el !== null) {
                    containers.current[index] = el;
                  }
                }}
              >
                <ReactHookCheckbox
                  labelPlacement="end"
                  name={`translations.${transKey}.isDefault`}
                  // setting other isDefault checkboxes to false.
                  onChange={(e) => {
                    currentLanguages.forEach((lg) => {
                      setValue(`translations.${lg}.isDefault`, false);
                    });
                    return e;
                  }}
                  label={t("isDefault")}
                />
                <ReactHookTextField
                  fullWidth
                  required
                  name={`translations.${transKey}.name`}
                  label={t("name", { locale: transKey })}
                />
                <ReactHookTextField
                  fullWidth
                  multiline
                  minRows={6}
                  name={`translations.${transKey}.description`}
                  label={t("description", { locale: transKey })}
                />
                <ReactHookTagInput
                  fullWidth
                  name={`translations.${transKey}.tags`}
                  label={t("tags", {
                    locale: transKey,
                  })}
                />
                <ReactHookFileInput
                  name={`attachments.${transKey}.thumbnailId`}
                  fileNameName={`attachments.${transKey}.thumbnailName`}
                  onChange={async (event) => {
                    const file = event.target.files && event.target.files[0];
                    if (!file) {
                      return undefined;
                    }
                    if (!file.type.includes("image")) {
                      errorToast(invalidThumbnailFileType);
                      return undefined;
                    }
                    const uploadResponse = await centrixFetch({
                      method: "post",
                      path: "/api/app/doc-document/save-file",
                      contentType: "multipart/form-data",
                      body: { file: file as unknown as string },
                    });
                    if (!uploadResponse.ok) {
                      errorToast(thumbnailUploadErrorMessage);
                      return undefined;
                    }
                    const { fileId, fileName } = await uploadResponse.json();
                    setValue(`attachments.${transKey}.thumbnailName`, fileName);
                    return fileId;
                  }}
                  label={t("thumbnailId", {
                    locale: transKey,
                  })}
                  inputProps={{
                    accept: "image/jpeg, image/png, image/webp, image/bmp",
                  }}
                />
                <SwitchySwitch2
                  stackProps={{ px: 0 }}
                  options={{
                    left: {
                      label: t("switchySwitchFileLabel"),
                      value: "file",
                    },
                    right: {
                      label: t("switchySwitchLinkLabel"),
                      value: "link",
                    },
                  }}
                  value={isDocumentUpload ? "file" : "link"}
                  onChange={(val) => {
                    setValue("isDocumentUpload", val === "file");
                  }}
                />
                <ComponentSwitcher
                  switchPosition={isDocumentUpload}
                  ComponentOne={
                    <Stack spacing={2}>
                      <ReactHookFileInput
                        name={`attachments.${transKey}.fileId`}
                        fileNameName={`attachments.${transKey}.fileName`}
                        onChange={async (e) => {
                          const file = e.target.files && e.target.files[0];
                          if (!file) {
                            return undefined;
                          }
                          const uploadResponse = await centrixFetch({
                            method: "post",
                            path: "/api/app/doc-document/save-file",
                            contentType: "multipart/form-data",
                            body: { file: file as unknown as string },
                          });
                          if (!uploadResponse.ok) {
                            errorToast(fileInputUploadErrorMessage);
                            return undefined;
                          }
                          const { fileId, fileName } =
                            await uploadResponse.json();
                          setValue(
                            `attachments.${transKey}.fileName`,
                            fileName
                          );
                          return fileId;
                        }}
                        required={
                          isDocumentUpload &&
                          (defaultLanguage === undefined ||
                            defaultLanguage === transKey)
                        }
                        label={t("fileId", { locale: transKey })}
                      />
                    </Stack>
                  }
                  ComponentTwo={
                    <ReactHookTextField
                      fullWidth
                      required={
                        !isDocumentUpload &&
                        (defaultLanguage === undefined ||
                          defaultLanguage === transKey)
                      }
                      name={`attachments.${transKey}.documentLink`}
                      label={t("documentLink", { locale: transKey })}
                    />
                  }
                />
              </Stack>
            </Slide>
          </Box>
        ))}
      </Box>
    </Stack>
  );
}
