import { useCentrixApi } from "core/hooks/useCentrixApi";
import { useRouter } from "next/router";

export function useNewsEventsTags(type: "news" | "events") {
  const { locale } = useRouter();
  return useCentrixApi({
    path: "/api/news-events/articles/Tags",
    parameters: { query: { Locale: locale!, Type: type } },
  });
}
