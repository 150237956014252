import { usePermissionChecker } from "core/hooks/usePermissionChecker";
import { useCanManageDMS } from "./useCanManageDMS";

export function useCanManage() {
  const { hasSomePermissions, hasAllPermissions } = usePermissionChecker();

  const canManageDms = useCanManageDMS();

  const canManageNews = hasSomePermissions([
    "NewsEvents.Articles.Create",
    "NewsEvents.Articles.Edit",
    "NewsEvents.Articles.Delete",
    "NewsEvents.Categories.Create",
    "NewsEvents.Categories.Edit",
    "NewsEvents.Categories.Delete",
  ]);

  const canManageCmsBlogPosts = hasAllPermissions([
    "CmsKit.BlogPosts.Create",
    "CmsKit.BlogPosts.Update",
    "CmsKit.BlogPosts.Delete",
    "CmsKit.BlogPosts.Publish",
  ]);

  const canManageAdvertisementBanners = hasSomePermissions([
    "Fixhub.AdvertisementBanners",
  ]);

  return (
    canManageDms ||
    canManageCmsBlogPosts ||
    canManageNews ||
    canManageAdvertisementBanners
  );
}
