import { useTranslation } from "react-i18next";
import { emailRegex } from "core/utils/emailRegex";
import { FieldPath, FieldValues } from "react-hook-form";
import {
  ReactHookTextField,
  ReactHookTextFieldProps,
} from "./ReactHookTextField";

export function ReactHookEmailInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ ...props }: ReactHookTextFieldProps<TFieldValues, TName>) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:ReactHookEmailInput",
  });
  return (
    <ReactHookTextField
      autoComplete="email"
      rules={{
        pattern: {
          message: t("invalidEmail"),
          value: emailRegex,
        },
      }}
      {...props}
    />
  );
}
