import { useCallback } from "react";
import { useRouter } from "next/router";
import {
  DocTranslationDto,
  TranslationKey,
  DocDocumentAttachmentDto,
  DocDocumentDto,
} from "../entities";
import { useCurrentTranslationExtractor } from "./useCurrentTranslationExtractor";
import { extractDefaultTranslationKey, extractAttachment } from "../utils";

export type ProcessedDocDocument = {
  document: DocDocumentDto;
  currentTranslation: DocTranslationDto | undefined;
  currentAttachment: DocDocumentAttachmentDto | undefined;
};

export function useDocumentProcessor() {
  const { locale } = useRouter();
  const translationExtractor = useCurrentTranslationExtractor();
  const processDocument = useCallback(
    (document: DocDocumentDto): ProcessedDocDocument => {
      // extract default translationKey from translations. undefined if
      const defaultTransKey = extractDefaultTranslationKey(
        document.translations ?? []
      );
      const currentTranslation = translationExtractor(
        document.translations ?? []
      );

      const currentAttachment = extractAttachment(
        document.attachments ?? [],
        locale as TranslationKey,
        defaultTransKey
      );
      return {
        document,
        currentAttachment,
        currentTranslation,
      };
    },
    [locale, translationExtractor]
  );
  return processDocument;
}
