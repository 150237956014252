import { useMemo } from "react";
import { useCentrixApi } from "core/hooks/useCentrixApi";
import { useDocumentProcessor } from "./useDocumentProcessor";

export function useDocumentById(documentId: string) {
  const processDocument = useDocumentProcessor();

  const { data, isError, isLoading, mutate } = useCentrixApi({
    path: "/api/app/doc-document/{id}",
    parameters: { path: { id: documentId } },
  });
  const processedDocument = useMemo(() => {
    if (isError || isLoading || !data) {
      return undefined;
    }
    return processDocument(data);
  }, [isError, isLoading, data, processDocument]);

  return {
    data,
    processedDocument,
    isError,
    isLoading,
    mutate,
  };
}
