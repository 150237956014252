import { useDMSFormErrorHandler, useCategories } from "features/DMS/hooks";
import Typography from "@mui/material/Typography";
import { useToaster } from "core/hooks/useToaster";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { DmsCategoryForm, defaultValues } from "./DmsCategoryForm";
import { CategoryFormValues } from "../entities";

interface DmsCategoryAddProps {
  cancelOnClick: () => void;
  docLibraryId: string;
  canManageLibraryContents: boolean;
}
export function DmsCategoryAdd({
  cancelOnClick,
  docLibraryId,
  canManageLibraryContents,
}: DmsCategoryAddProps) {
  const formMethods = useForm<CategoryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;
  const { mutate } = useCategories({
    parentId: docLibraryId,
    mode: "manage",
    canManageLibraryContents,
  });
  const centrixFetch = useCentrixFetch();
  const { successToast, errorToast } = useToaster();
  const { t } = useTranslation("DocumentManagementSystem");
  const { errorHandler, scrollToRef } = useDMSFormErrorHandler(
    formMethods,
    "CategoryForm"
  );
  const successMessage = t(
    "Next:DocumentManagementSystem:CategoryAdd.POSTSuccess"
  );
  const errorMessage = t("Next:DocumentManagementSystem:CategoryAdd.POSTError");

  async function onValid({
    translations: translationsObject,
  }: CategoryFormValues) {
    const translations = Object.values(translationsObject).filter(
      (entry): entry is typeof entry & { name: string } =>
        typeof entry.name === "string"
    );
    const categoryResponse = await centrixFetch({
      method: "post",
      path: "/api/app/doc-category",
      body: { docLibraryId, translations },
    });
    if (!categoryResponse.ok) {
      errorToast(errorMessage);
      return;
    }
    await mutate();
    successToast(successMessage);
    cancelOnClick();
  }

  function clearOnClick() {
    formMethods.reset(defaultValues);
  }

  return (
    <>
      <Typography
        variant="h3"
        textAlign="center"
        color="primary.main"
        my={3}
        ref={scrollToRef}
      >
        {t("Next:DocumentManagementSystem:CategoryAdd.title")}
      </Typography>
      <DmsCategoryForm
        formMethods={formMethods}
        onSubmit={handleSubmit(onValid, errorHandler)}
        cancelOnClick={cancelOnClick}
        clearOnClick={clearOnClick}
        mode="add"
        formType="CategoryForm"
        isSubmitting={isSubmitting}
      />
    </>
  );
}
