export function determineDMSDocumentType(
  documentLink: string | undefined,
  fileName: string | undefined,
  fileId: string | undefined
):
  | { type: "link"; documentLink: string }
  | { type: "pdf"; fileName: string; fileId: string }
  | { type: "otherFile"; fileName: string; fileId: string }
  | { type: "invalid" } {
  if (documentLink) {
    return { type: "link", documentLink };
  }
  if (fileName && fileName.split(".").at(-1) === "pdf" && fileId) {
    return {
      type: "pdf",
      fileName,
      fileId,
    };
  }
  if (fileName && fileId) {
    return { type: "otherFile", fileId, fileName };
  }
  return { type: "invalid" };
}
