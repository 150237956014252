import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Head from "next/head";
import { useAuth } from "oidc-react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { POST_LOGIN_PATH_SESSION_STORAGE_KEY } from "core/components/Authorize";
import { LogoutPageHeader } from "../components/LogoutPageHeader";

export function LogoutPage() {
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:LogoutPage" });
  const { signIn } = useAuth();
  useEffect(() => {
    sessionStorage.setItem(POST_LOGIN_PATH_SESSION_STORAGE_KEY, "/");
  }, []);
  return (
    <div>
      <Head>
        <title>{t("title")}</title>
      </Head>
      <LogoutPageHeader />
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Stack alignItems="center" gap={4}>
          <Typography variant="h3" component="h1">
            {t("logoutMessageTitle")}
          </Typography>
          <Typography variant="h4" component="h1">
            {t("logoutMessageParagraph")}
          </Typography>
          <Button
            size="large"
            variant="contained"
            onClick={async () => {
              await signIn();
            }}
          >
            {t("login")}
          </Button>
        </Stack>
      </Container>
    </div>
  );
}
