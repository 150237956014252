import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import { BrandCode, RegionCode } from "core/entities/brandDefs";

export function InvalidSelectionWarning({
  selectedBrands,
  selectedRegions,
  selectedDepartments,
  brandRegionMap,
  departmentBrandMap,
}: {
  selectedBrands: string[];
  selectedRegions: string[];
  selectedDepartments: string[];
  brandRegionMap: Partial<Record<BrandCode, RegionCode[]>>;
  departmentBrandMap: Record<string, string[]>;
}) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:ReactHookDMSViewablePermissions",
  });
  const { t: tCore } = useTranslation("Fixhub");
  const { isIn, warningMessage } = useMemo(() => {
    // check if the selected brands and regions are valid
    // eslint-disable-next-line no-restricted-syntax
    for (const brand of selectedBrands) {
      const brandRegions = brandRegionMap[brand as BrandCode];
      const invalidRegion = selectedRegions.find(
        (region) => !brandRegions?.includes(region as RegionCode)
      );
      if (invalidRegion) {
        const invalidRegionWarning = t("invalidRegionWarning", {
          brand: tCore(`Brand:BrandName.${brand}`),
          region: tCore(`Region:RegionName.${invalidRegion as string}`),
        });
        return { isIn: true, warningMessage: invalidRegionWarning };
      }
    }
    // check if the selected departments are valid
    // eslint-disable-next-line no-restricted-syntax
    for (const department of selectedDepartments) {
      const departmentBrands = departmentBrandMap[department];
      if (!departmentBrands) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const invalidBrand = selectedBrands.find(
        (brand) => !departmentBrands?.includes(brand)
      );
      if (invalidBrand) {
        const invalidDepartmentWarning = t("invalidDepartmentWarning", {
          brand: tCore(`Brand:BrandName.${invalidBrand}`),
          department,
        });
        return { isIn: true, warningMessage: invalidDepartmentWarning };
      }
    }

    return { isIn: false, warningMessage: null };
  }, [
    brandRegionMap,
    departmentBrandMap,
    selectedBrands,
    selectedDepartments,
    selectedRegions,
    t,
    tCore,
  ]);

  return (
    <Collapse in={isIn}>
      <Typography
        sx={{ whiteSpace: "pre-wrap" }}
        variant="caption"
        color="warning.main"
      >
        {warningMessage}
      </Typography>
    </Collapse>
  );
}
