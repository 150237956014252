import ListItem, { ListItemProps } from "@mui/material/ListItem";
import { ReactNode } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { CustomLink } from "core/components/CustomLink";
import { TagsDisplay } from "core/components/TagsDisplay";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useBreakpoints } from "core/hooks/useBreakpoints";

export interface SharedDMSWidgetListItemProps extends ListItemProps {
  dateText: string;
  dateLabel: string;
  tags: string[];
  parents: { id: string; name: string; href?: string | undefined }[];
}

interface DMSWidgetListItemTemplateProps extends SharedDMSWidgetListItemProps {
  AvatarSlot: ReactNode;
  TitleSlot: ReactNode;
  ButtonSlot: ReactNode;
}

export function DMSWidgetListItemTemplate({
  AvatarSlot,
  TitleSlot,
  ButtonSlot,
  parents = [],
  dateLabel,
  dateText,
  tags,
  sx,
}: DMSWidgetListItemTemplateProps) {
  const { isMobile } = useBreakpoints();

  const ParentBreadCrumbs = (
    <Breadcrumbs
      sx={{
        pl: isMobile ? 1 : 0,
      }}
      separator=">"
    >
      {parents
        .filter(({ href }) => Boolean(href))
        .map(({ id, name, href }) =>
          href ? (
            <CustomLink
              sx={{ lineHeight: 1 }}
              variant="overline"
              fontWeight={500}
              key={id}
              href={href}
            >
              {name}
            </CustomLink>
          ) : (
            <Typography sx={{ lineHeight: 1 }} key={id} variant="overline">
              {name}
            </Typography>
          )
        )}
      <span />
    </Breadcrumbs>
  );

  if (isMobile) {
    return (
      <ListItem
        disablePadding
        disableGutters
        sx={{
          ...sx,
          pl: 1,
          py: 1,
          gap: 1,
        }}
      >
        <Stack alignItems="flex-start" flex={1}>
          {ParentBreadCrumbs}
          <Stack flex={1} direction="row" alignItems="center" spacing={2}>
            {AvatarSlot}
            <Stack>
              {TitleSlot}
              <Stack direction="row" spacing={1}>
                <Typography variant="body2" color="text.secondary">
                  {dateLabel}: {dateText}
                </Typography>
              </Stack>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                fontStyle="italic"
              >
                {tags.map((tag) => `#${tag}`).join(", ")}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Box
          sx={{
            width: "75px",
            borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
            alignSelf: "stretch",
            display: "grid",
          }}
        >
          {ButtonSlot}
        </Box>
      </ListItem>
    );
  }

  return (
    <ListItem
      disablePadding
      sx={{
        ...sx,
        py: 1,
        pl: 1,
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "auto 2fr 1fr 1fr 150px",
        columnGap: 4,
        rowGap: 2,
      }}
    >
      <Box>{AvatarSlot}</Box>
      <Stack>
        {ParentBreadCrumbs}
        {TitleSlot}
      </Stack>
      <Stack>
        <Typography variant="body2" color="text.secondary">
          {dateLabel}
        </Typography>
        <Typography variant="body2" fontWeight="bold">
          {dateText}
        </Typography>
      </Stack>

      <TagsDisplay tags={typeof tags === "string" ? [tags] : (tags ?? [])} />

      <Box
        display="grid"
        height="100%"
        justifySelf="stretch"
        sx={{
          placeItems: "stretch",
          borderLeft: (theme) =>
            isMobile ? undefined : `1px solid ${theme.palette.divider}`,
        }}
      >
        {ButtonSlot}
      </Box>
    </ListItem>
  );
}
