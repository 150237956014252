// Using this recipe from MUI documentation for integrating the Nextjs Link Component with the Mui Link Component
// https://github.com/mui/material-ui/blob/3d8e3ea7c4b407968deccd494924f1a4b5d4bc61/examples/nextjs-with-typescript/src/Link.tsx

/* eslint-disable prefer-arrow-callback */
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import React, { useMemo } from "react";
import { useRouter } from "next/router";
import { useRegion } from "core/hooks/useRegion";

const Anchor = styled("a")({});

// updated to reflect changes https://github.com/mui/material-ui/blob/a0ccc0c603273d0874542bfa29875bb44c884f1f/examples/nextjs-with-typescript/src/Link.tsx
interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href">,
    Omit<
      NextLinkProps,
      "href" | "as" | "onClick" | "onMouseEnter" | "onTouchStart"
    > {
  to: NextLinkProps["href"];
  linkAs?: NextLinkProps["as"];
}

export const NextLinkComposed = React.forwardRef<
  HTMLAnchorElement,
  NextLinkComposedProps
>(function NextLinkComposed(props, ref) {
  const {
    to,
    linkAs,
    replace,
    scroll,
    shallow,
    prefetch,
    locale,
    passHref,
    ...other
  } = props;

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
      ref={ref}
      {...other}
    />
  );
});

export type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps["as"];
  href: NextLinkProps["href"];
  linkAs?: NextLinkProps["as"]; // Useful when the as prop is shallow by styled().
  noLinkStyle?: boolean;
  rawHref?: boolean;
} & Omit<NextLinkComposedProps, "to" | "linkAs" | "href"> &
  Omit<MuiLinkProps, "href">;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
const LinkMuiNext = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function LinkMuiNext(props, ref) {
    const {
      activeClassName = "active",
      as,
      className: classNameProps,
      href,
      linkAs: linkAsProp,
      locale,
      noLinkStyle,
      prefetch,
      replace,
      role, // Link don't have roles.
      scroll,
      shallow,
      passHref, // remove next link property
      ...other
    } = props;

    const router = useRouter();
    const pathname = typeof href === "string" ? href : href?.pathname;
    const className = clsx(classNameProps, {
      [activeClassName]: router.pathname === pathname && activeClassName,
    });

    const isExternal =
      typeof href === "string" &&
      (href.indexOf("http") === 0 || href.indexOf("mailto:") === 0);

    if (isExternal) {
      if (noLinkStyle) {
        return (
          <Anchor className={className} href={href} ref={ref} {...other} />
        );
      }

      return <MuiLink className={className} href={href} ref={ref} {...other} />;
    }

    const linkAs = linkAsProp || as;
    const nextjsProps = {
      to: href,
      linkAs,
      replace,
      scroll,
      shallow,
      prefetch,
      locale,
    };

    if (noLinkStyle) {
      return (
        <NextLinkComposed
          className={className}
          ref={ref}
          {...nextjsProps}
          {...other}
        />
      );
    }

    return (
      <MuiLink
        component={NextLinkComposed}
        className={className}
        ref={ref}
        {...nextjsProps}
        {...other}
      />
    );
  }
);

/**
 * A styled version of the Next.js Link component
 * that supports the region and locale.
 *
 * The majority of the the links in the application need to be relative to the region
 * and locale.
 * This component will automatically prepend the region and locale to absolute href ex. `/payment-request` -> `/en/ca/payment-request`.
 * Exceptions can be made by setting the rawHref prop to true, in which case the href will be used as is.
 * Routes beginning with /api/ will also not not be modified.
 *
 * @param rawHref - If true, the href will be used as is.
 *
 * @example
 * ```tsx
 * <CustomLink href="/about">About</CustomLink> // href="/en/ca/about"
 * <CustomLink href="/about" rawHref>About</CustomLink> // href="/about"
 * <CustomLink href="/api/test">Test</CustomLink> // href="/api/test"
 * ```
 */
export const CustomLink = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<LinkProps>
>(({ href, children, passHref, rawHref, underline, ...rest }, ref) => {
  const region = useRegion();
  const { locale } = useRouter();
  const actualHref = useMemo(() => {
    if (rawHref) {
      return href;
    }
    const hrefString = href?.toString();
    if (hrefString.startsWith("/api/")) {
      return hrefString;
    }
    if (hrefString.startsWith("/")) {
      return `/${locale}/${region}${href}`;
    }
    return hrefString;
  }, [href, locale, region, rawHref]);

  return (
    <LinkMuiNext
      {...rest}
      passHref={passHref}
      underline="none"
      href={actualHref}
      ref={ref}
    >
      {children}
    </LinkMuiNext>
  );
});
CustomLink.displayName = "CustomLink";
