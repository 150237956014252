import { PermissionContextDto } from "core/entities";
import {
  BRAND_PERMISSION_CONTEXT_KEY,
  REGION_PERMISSION_CONTEXT_KEY,
} from "core/entities/permissionContextDefs";

export function extractRegionsForBrand(
  permissionContexts: PermissionContextDto[],
  brandCode: string
): string[] {
  return permissionContexts.reduce((acc, { context: contextList }) => {
    if (!Array.isArray(contextList)) {
      return acc;
    }
    const isCurrentBrand = contextList.some(
      ({ key, value }) =>
        typeof key === "string" &&
        typeof value === "string" &&
        key.toLowerCase() === BRAND_PERMISSION_CONTEXT_KEY &&
        value.toLowerCase() === brandCode.toLowerCase()
    );
    if (!isCurrentBrand) {
      return acc;
    }
    const region = contextList.find(
      ({ key }) =>
        typeof key === "string" &&
        key.toLowerCase() === REGION_PERMISSION_CONTEXT_KEY
    );
    if (!region) {
      return acc;
    }
    const regionContextValue = region.value;
    if (typeof regionContextValue !== "string") {
      return acc;
    }
    const regionCode = regionContextValue.slice(-2);
    if (regionCode && !acc.includes(regionCode)) {
      acc.push(regionCode);
    }
    return acc;
  }, [] as string[]);
}
