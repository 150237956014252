import { PermissionContextDto } from "core/entities";

export function extractValueFromContext(
  contextList: PermissionContextDto["context"],
  key: string
) {
  if (!Array.isArray(contextList)) {
    return undefined;
  }
  const context = contextList.find(
    ({ key: contextKey }) =>
      typeof contextKey === "string" &&
      contextKey.toLowerCase() === key.toLowerCase()
  );
  return context?.value;
}
