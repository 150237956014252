import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { GridAreaContainer } from "core/components/GridAreaContainer";
import { ErrorComponent } from "core/components/ErrorComponent";
import { CustomLink } from "core/components/CustomLink";
import { Loading } from "core/components/Loading";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import Stack from "@mui/material/Stack";
import { EventsWidgetTile } from "./EventsWidgetTile";
import { useEventsWidgetArticles } from "./useEventsWidgetArticles";
import { BigEventWidgetTile } from "./BigEventWidgetTile";

export function EventsWidget() {
  const { t } = useTranslation("NewsEvents", {
    keyPrefix: "Next:NewsEvents:EventsWidget",
  });
  const { items, isLoading, isError } = useEventsWidgetArticles(4);
  const { isDesktop, isMobile } = useBreakpoints();

  if (isError) {
    return (
      <GridAreaContainer
        gridArea="events"
        title={t("gridAreaContainerTitle")}
        BottomRightSlot={
          <Button
            href="/events"
            LinkComponent={CustomLink}
            startIcon={<AddIcon />}
          >
            {t("seeAllEvents")}
          </Button>
        }
      >
        <Paper
          sx={{
            p: 3,
            height: "100%",
          }}
        >
          <ErrorComponent />
        </Paper>
      </GridAreaContainer>
    );
  }

  if (isLoading) {
    return (
      <GridAreaContainer
        gridArea="events"
        title={t("gridAreaContainerTitle")}
        BottomRightSlot={
          <Button
            href="/events"
            LinkComponent={CustomLink}
            startIcon={<AddIcon />}
          >
            {t("seeAllEvents")}
          </Button>
        }
      >
        <Paper
          sx={{
            p: 3,
            height: "100%",
          }}
        >
          <Loading textDisabled />
        </Paper>
      </GridAreaContainer>
    );
  }

  if (!items.length) {
    return (
      <GridAreaContainer
        gridArea="events"
        title={t("gridAreaContainerTitle")}
        BottomRightSlot={
          <Button
            href="/events"
            LinkComponent={CustomLink}
            startIcon={<AddIcon />}
          >
            {t("seeAllEvents")}
          </Button>
        }
      >
        <Paper
          sx={{
            p: 3,
            height: "100%",
          }}
        >
          <Typography variant="body1" color="text.secondary">
            {t("noEvents")}
          </Typography>
        </Paper>
      </GridAreaContainer>
    );
  }

  const [firstEvent, ...otherEvents] = items;
  return (
    <GridAreaContainer
      gridArea="events"
      title={t("gridAreaContainerTitle")}
      BottomRightSlot={
        <Button
          href="/events"
          LinkComponent={CustomLink}
          startIcon={<AddIcon />}
        >
          {t("seeAllEvents")}
        </Button>
      }
    >
      <Paper
        sx={{
          p: 3,
          height: "100%",
        }}
      >
        <Stack direction={isDesktop || isMobile ? "column" : "row"} gap={4}>
          {firstEvent && (
            <BigEventWidgetTile
              showBottomBorder={isDesktop || isMobile}
              {...firstEvent}
            />
          )}

          <Stack
            sx={{
              flex: 1,
              "& > *:not(:last-of-type)": {
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                paddingBottom: 2,
              },
            }}
            direction="column"
            gap={2}
          >
            {otherEvents.map((event) => (
              <EventsWidgetTile key={event.id} {...event} />
            ))}
          </Stack>
        </Stack>
      </Paper>
    </GridAreaContainer>
  );
}
