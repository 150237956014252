import { useRef } from "react";
import { useIntlFormatterLocales } from "./useIntlFormatterLocales";
import { usePreferredTemperatureUnit } from "./usePreferredTemperatureUnit";

export function useFormatTemperature(options?: Intl.NumberFormatOptions) {
  const locales = useIntlFormatterLocales();
  const optionsRef = useRef(options);
  const { value: tempUnit } = usePreferredTemperatureUnit();
  const { format } = new Intl.NumberFormat(locales, {
    style: "unit",
    unit: tempUnit === "C" ? "celsius" : "fahrenheit",
    ...optionsRef.current,
  });
  return format;
}
