import { ReactNode, useMemo } from "react";
import {
  ThemeOptions,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { BrandCode } from "core/entities";
import { useRouter } from "next/router";
import {
  enUS as coreEn,
  frFR as coreFr,
  deDE as coreDe,
  esES as coreEs,
} from "@mui/material/locale";
import {
  enUS as gridEn,
  frFR as gridFr,
  deDE as gridDe,
  esES as gridEs,
} from "@mui/x-data-grid-pro";
import {
  enUS as datePickersEn,
  frFR as datePickersFr,
  deDE as datePickersDe,
  esES as datePickersEs,
} from "@mui/x-date-pickers-pro";
import { deepmerge } from "@mui/utils";
import { getBrand } from "core/utils/getBrand";
import { useExtensoToken } from "core/hooks/useExtensoToken";
import { BaseThemeObject } from "core/theme/BaseThemeObject";
import { ExtensoThemeOptions } from "core/theme/ExtensoTheme";

const localeMap = new Map([
  ["en", deepmerge(deepmerge(coreEn, gridEn), datePickersEn)],
  ["fr", deepmerge(deepmerge(coreFr, gridFr), datePickersFr)],
  ["de", deepmerge(deepmerge(coreDe, gridDe), datePickersDe)],
  ["es", deepmerge(deepmerge(coreEs, gridEs), datePickersEs)],
]);

export function MUIThemeProvider({
  children,
  brandCode,
  customTheme,
}: {
  children: ReactNode;
  brandCode?: BrandCode;
  customTheme?: ThemeOptions | null;
}) {
  const { themeOptions: theme } = getBrand(brandCode);
  const { locale } = useRouter();

  const localizationResource = localeMap.get(locale ?? "en")!;

  const { token: extensoToken } = useExtensoToken();
  const { pathname } = useRouter();
  const currentTheme = useMemo(() => {
    let baseThemeOptions = deepmerge(BaseThemeObject, theme);
    if (extensoToken || pathname === "/extenso-redirect") {
      baseThemeOptions = deepmerge(BaseThemeObject, ExtensoThemeOptions);
    }
    if (customTheme) {
      baseThemeOptions = deepmerge(BaseThemeObject, customTheme);
    }
    const themeWithLocalization = createTheme(
      baseThemeOptions,
      localizationResource
    );
    const responsiveTypographyTheme = responsiveFontSizes(
      themeWithLocalization
    );
    return responsiveTypographyTheme;
  }, [theme, extensoToken, pathname, customTheme, localizationResource]);

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
}
