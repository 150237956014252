// Importing types from react-hook-form
import { FieldErrors, FieldError } from "react-hook-form";

// A function that accepts an object of errors from react-hook-form and returns a mapping of error messages and their corresponding field names
export function getFieldNameErrorObjectMap(
  errorsObject: FieldErrors
): Record<string, FieldError> {
  // An array that will hold an array of strings and error objects representing the error messages and their corresponding field names
  const keyPaths: (string | FieldError)[][] = [];

  // A function that checks whether an object is a FieldError object
  function isObjectErrorObject(obj: any): obj is FieldError {
    return (
      typeof obj === "object" && // Check that obj is an object
      "message" in obj && // Check that obj has a 'message' property
      "ref" in obj && // Check that obj has a 'ref' property
      "type" in obj // Check that obj has a 'type' property
    );
  }

  // A recursive function that traverses through the error object and extracts the key path and error object
  function getKeyPath(obj: any, currentPath: string[] = []) {
    // If the current value is not an object, return
    if (typeof obj !== "object") {
      return;
    }

    // If the current value is a FieldError object, add the current path and FieldError object to the keyPaths array
    if (isObjectErrorObject(obj)) {
      keyPaths.push([currentPath.join("."), obj]);
      return;
    }

    // If the current value is an object, traverse through its properties recursively
    Object.entries(obj).forEach(([key, value]) => {
      getKeyPath(value, [...currentPath, key]);
    });
  }

  // Call the getKeyPath function with the errorsObject passed as a parameter
  getKeyPath(errorsObject);

  // Use Object.fromEntries to create an object from the array of key paths
  // Each string in the array represents a field name, and the corresponding FieldError object represents the error associated with that field name
  return Object.fromEntries(keyPaths);
}
