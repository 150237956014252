import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";
import { MyLinkType } from "features/MyLinks/entities";
import { ReactNode, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import StarIcon from "@mui/icons-material/Star";
import ListItemText from "@mui/material/ListItemText";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import { FavoriteExtendedDto, ItemType } from "core/entities";
import { useTranslation } from "react-i18next";
import { ErrorComponent } from "core/components/ErrorComponent";
import { GridAreaContainer } from "core/components/GridAreaContainer";
import { Loading } from "core/components/Loading";
import { CustomLink } from "core/components/CustomLink";
import { useFavorites } from "features/MyLinks/hooks";
import Button from "@mui/material/Button";
import { getMyLinksHref } from "../../utils/getMyLinksHref";
import { MyLinksIcon } from "../MyLinksIcon";

function MyLinksWidgetContainer({ children }: { children: ReactNode }) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:MyLinks:MyLinksWidget",
  });

  return (
    <GridAreaContainer
      gridArea="links"
      title={t("gridAreaContainerTitle")}
      BottomRightSlot={
        <Button
          href="/my-links"
          startIcon={<AddIcon />}
          LinkComponent={CustomLink}
        >
          {t("goToPage")}
        </Button>
      }
    >
      <Paper sx={{ py: 2, px: 2, height: "100%", width: "100%" }}>
        {children}
      </Paper>
    </GridAreaContainer>
  );
}

export function MyLinksWidget() {
  const [currentTab, setCurrentTab] = useState<MyLinkType>("favorite");
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:MyLinks:MyLinksWidget",
  });
  const { data: favoritesData, isLoading, isError } = useFavorites();

  if (isLoading) {
    return (
      <MyLinksWidgetContainer>
        <Loading textDisabled />
      </MyLinksWidgetContainer>
    );
  }
  if (isError) {
    return (
      <MyLinksWidgetContainer>
        <ErrorComponent />
      </MyLinksWidgetContainer>
    );
  }
  const tabItemsMap: Record<MyLinkType, FavoriteExtendedDto[]> = {
    favorite: favoritesData?.items ?? [],
    frequent: [],
    quick: [],
  };
  const currentItems = tabItemsMap[currentTab];
  return (
    <MyLinksWidgetContainer>
      <Stack spacing={2}>
        <Tabs
          value={currentTab}
          onChange={(_, newValue: MyLinkType) => setCurrentTab(newValue)}
        >
          <Tab label={<StarIcon />} value="favorite" />
          {/* <Tab label={<LinkIcon />} value="quick" /> */}
          {/* <Tab label={<RestoreIcon />} value="frequent" /> */}
        </Tabs>
        <List dense>
          {currentItems.length === 0 && (
            <Typography color="text.secondary">
              {t(`${currentTab}.noItems`)}
            </Typography>
          )}
          {currentItems
            .filter(
              (
                item
              ): item is Omit<typeof item, "itemType" | "itemId"> & {
                itemType: ItemType;
                itemId: string;
              } =>
                typeof item.itemType === "string" &&
                typeof item.itemId === "string"
            )
            .slice(0, 6)
            .map(({ id, itemType, itemId, title }) => (
              <ListItem key={id} disablePadding>
                <ListItemButton
                  component={CustomLink}
                  href={getMyLinksHref(itemType, itemId)}
                >
                  <ListItemIcon>
                    <MyLinksIcon itemType={itemType} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ color: "primary.main" }}
                    primary={title ?? `${itemType}:${itemId}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Stack>
    </MyLinksWidgetContainer>
  );
}
