import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";

export function SkipToMainContentLink() {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:SkipToContent",
  });
  return (
    <Link
      sx={{
        position: "absolute",
        left: "-9999px",
        top: "1rem",
        padding: 1,
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        opacity: 0,
        zIndex: 9999,
        "&:focus": {
          left: "50%",
          transform: "translateX(-50%)",
          opacity: 1,
        },
      }}
      href="#main-content"
    >
      {t("label")}
    </Link>
  );
}
