import { ThemeOptions } from "@mui/material/";

export const ExtensoThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#006BB6",
    },
    secondary: {
      main: "#f33d48",
    },
    warning: {
      main: "#ef3e43",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  shape: {
    borderRadius: 4, // reset to default value
  },
};
